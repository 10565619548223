<template>
  <div id="app">
    <div class="alert-message-box">
      <Toastr />
      <Alert
        v-if="announcement.content && !announcement.closed"
        type="info"
        :content="sanitizeHTML(announcement.content)"
        @close="handleCloseMaintenanceNotice" />
      <Alert
        v-if="offlineError"
        type="error"
        content="Error: Your browser is not able to connect to MarineTraffic Inbox. Are you offline?"
        @close="handleCloseOfflineErrorMessage" />
      <Modal 
        v-model="synchronizingData" 
        footer-hide
        :closable="false"
      >
        <div slot="header" style="text-align: center; font-weight: bold;">
          Logging out
        </div>
        <div style="display: flex; justify-content: center; align-items: center; gap: 8px;">
          <Icon type="md-sync" size="20" />
          <span>Please wait while we sync your data</span>
        </div>
      </Modal>
    </div>
    <router-view @route-data-loaded="changeTitle" v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
  import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
  import util from 'util';
  import api from 'api'
  import appConstant from '@/common/constants/app.constant';
  import { hotkeyHandler } from '@/mixins';
  import Alert from './components/Alert.vue';
  import md5 from 'md5';
  import Toastr from './components/Toastr.vue';
  import dayjs from 'dayjs';
  import * as Sentry from "@sentry/vue";

  let readMailInterval, unreadCountInterval, announcementInterval;
  export default {
    name: 'app',
    data() {
      return {
        savedPinMenu: false,
        isRouterAlive: true,
        showNotiBanner: true,
        announcement: {
          content: '',
          md5: '',
          closed: true
        },
        offlineError: false
      };
    },
    computed: {
      ...mapState(["online"]),
      ...mapGetters([
        'userInfo',
        'setting_mails',
        'setting_company',
        'inappMailState',
        'synchronizingData'
      ]),
    },
    mixins: [hotkeyHandler],
    components: {
      Alert,
      Toastr,
    },
    methods: {
      ...mapActions([
        'composeMail',
        'getTags',
        'unlockDraft',
        'deleteDraft',
        'syncAutoBookmark',
        'getHelpCenterVersion'
      ]),
      changeTitle(title) {
        if (title) {
          document.title = `${title}`;
        }
      },
      reload () {
        this.isRouterAlive = false;
        this.$nextTick(() => (this.isRouterAlive = true))
      },
      configMessage() {
        this.$Message.config({
          duration: 3
        });
      },
      handleHotkey(e) {
        if(e.key == 'n' || (e.key == 'n' && (e.ctrlKey || e.metaKey))) {
          e.preventDefault();
          this.composeMail({inNewWindow: this.composeInNewWindow});
        }
      },
      handleSocket() {
        if(!this.$ActionCable || !this.$ActionCable.subscriptions)
          return;
        let socket = this.$ActionCable.subscriptions.subscriptions[0];
        let self = this;
        socket.received.companyTag = function(tags) {
          self.$store.commit('SET_CONFIGURED_TAGS', tags);
        }
        socket.received.trigonalEmailDraft = function({email_id}) {
          if(!email_id) return
          
          self.composeMail({
            path: "sendMail",
            query: {
              method: 'edit',
              id: email_id
            },
            inNewWindow: true
          })
        }
      },
      setupAnnouncementInterval() {
        if (/previewEml|sendmail|mail\/\d+/i.test(window.location.href)) return;

        this.fetchAnnouncement();
        announcementInterval = setInterval(_ => {
          this.fetchAnnouncement();
        }, 1000 * 60 * 30)
        util.functionBus.removeAnnouncementInterval = () => clearInterval(announcementInterval)
      },
      fetchAnnouncement() {
        api.fetch_announcement().then(data => {
          const content = data && data.data || '';
          let savedAnnouncement = localStorage.getItem("announcement")

          if (!savedAnnouncement) {
            savedAnnouncement = {}
          } else {
            savedAnnouncement = JSON.parse(savedAnnouncement)
          }

          const newAnnouncement = {
            content,
            md5: md5(content),
            closed: false
          }

          if (savedAnnouncement.md5 === newAnnouncement.md5 && savedAnnouncement.closed) return;

          this.announcement = newAnnouncement;
          savedAnnouncement.md5 !== newAnnouncement.md5 && localStorage.setItem("announcement", JSON.stringify(newAnnouncement));
        }).catch(_ => {
          this.announcement = {
            content: '',
            md5: '',
            closed: true
          };
        });
      },
      onVisibilityChanged() {
        if(document.hidden)
          clearInterval(announcementInterval);
        else
          this.setupAnnouncementInterval();
      },
      handleCloseMaintenanceNotice() {
        this.announcement.closed = true;
        localStorage.setItem("announcement", JSON.stringify(this.announcement));
        clearInterval(announcementInterval);
      },
      handleCloseOfflineErrorMessage() {
        this.offlineError = false
      },
      handlePopupClose(event) {
        if(!event || !event.detail) return
        switch(event.detail.action) {
          case 'unlock_draft':
            event.detail.id && this.unlockDraft(event.detail.id)
            break
          case 'delete_draft':
            event.detail.id && this.deleteDraft(event.detail.id)
            break
        }
      }
    },
    created() {
      setTimeout(() => {
        const token = util.getToken();
        if(typeof(token) == 'string' && !['previewEmlWindow', 'sendMailWindow'].includes(this.$route.name)) {
          this.$store.dispatch("getRoles");
          this.$store.dispatch("getUserSettings");
          this.$store.dispatch("getCompanySettings");
          this.setting_company.limit_hashtag == '1' && this.$store.dispatch("getTags")
          this.$store.dispatch("GetTeamMembers", { active: true });
          !location.href.includes("calendar") && this.setting_company.calendar_module == '1' && this.$store.dispatch("getCalendarResources").then(() => {
            this.$store.dispatch("getCalendarEvents", {
              start: dayjs().subtract(1, 'day').toISOString(),
              end: dayjs().add(1, 'day').toISOString()
            })
          });
          this.syncAutoBookmark();
          this.getHelpCenterVersion();

          process.env.NODE_ENV === "production" && Sentry.setUser({ id: util.getUserId() });
        }
      }, 500);
      this.setupAnnouncementInterval();
      util.changeTheme(this.setting_mails.theme_colour || appConstant.themeNames.ORIGINAL);
    },
    mounted() {
      this.handleSocket();
      this.configMessage();
      window.addEventListener("offline", () => {
        util.sendMessageToSw({
          message: "checkOnlineStatus",
        });
      });
      window.addEventListener("popupbeforeunload", this.handlePopupClose)
      readMailInterval = setInterval(() => {
        this.online && this.$store.dispatch("readMultipleEmails", { retry: true });
      }, 5*1000);
      unreadCountInterval = setInterval(() => {
        this.online && this.$store.dispatch("loadLines");
      }, 1000*60*60*6); // 6 hours
    },
    beforeDestroy() {
      clearInterval(readMailInterval);
      clearInterval(unreadCountInterval);
      clearInterval(announcementInterval);
    },
    watch: {
      online(val) {
        this.offlineError = !val
      }
    }
  };
</script>

<style lang="scss">
  @import "../public/static/style/public.scss";
  @import "./common/scss/common";
  @import "./common/iconfont/iconfont.css";
  @import "./common/iconfont_v2/style.css";

  .notice {
    position: relative;
    height: 40px;
    line-height: 40px;
    font-size: 13px;
    background-color: var(--background-color);
    color: var(--primary-color);
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      margin-right: 5px;
      font-size: 16px;
    }
    a {
      padding: 0 5px;
      text-decoration: underline;
    }
  }
  .maintenance-notice {
    background-color: red;
    color: #fff;
    position: relative;
    padding: 0 20px;
    height: 20px;
    line-height: 20px;

    i {
      position: absolute;
      right: 10px;
      padding: 3px;
      border-radius: 50%;
      font-size: 10px;
      &:hover {
        background-color: rgba(0,0,0,.2);
        cursor: pointer;
      }
    }
  }
  .hide-overflow {
    overflow: hidden;
  }
  .alert-message-box {
    position: fixed;
    top: 8px;
    right: 8px;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
</style>
