<template>
  <div class="container-wrapper addContact">
    <!--loading-->
    <div class="loading" v-show="addLoading">
      <Spin size="large" fix></Spin>
    </div>
    <section class="container">
      <!--title-->
      <header class="headerTitle">
        <div class="leftSide">
          <h2 @click="$router.go(-1)">
            <i class="iconfont ch-icon-arrow_right backIcon"></i>
            {{pageName}}
          </h2>
        </div>
        <div class="rightSide">
          <Button type="primary" @click="handleSubmit('formValidate')">Save</Button>
        </div>
      </header>
      <!--contain-->
      <div class="contain-wrapper">
        <!--formData-->
        <div class="form-contain">
          <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" label-position="left" :label-width="135">
            <!--avatar-->
            <Form-item label="Profile Photo" prop="avatar">
              <div class="imgUpload">
                <avatar class="avatar" :fullname="formValidate.name" :avatarURL="imgPreview" :width="60"></avatar>
                <div class="handleUpload">
                  <Upload action="//jsonplaceholder.typicode.com/posts/"
                          :format="['jpg','gif','png']"
                          :max-size="2048"
                          :before-upload="handleBeforeUpload">
                    <Button>Change photo</Button>
                  </Upload>
                  <span>.jpg .gif or .png. Max file size 2MB</span>
                </div>
              </div>
            </Form-item>
            <Form-item label="First name" prop="first_name">
              <Input v-model.trim="formValidate.first_name" @on-change="onChangeName"/>
            </Form-item>
            <Form-item label="Last name" prop="last_name">
              <Input v-model.trim="formValidate.last_name" @on-change="onChangeName"/>
            </Form-item>
            <!--Name-->
            <Form-item label="Full name" prop="name">
              <Input v-model.trim="formValidate.name" />
            </Form-item>
            <!--Salutation-->
            <Form-item v-for="(item, index) in formValidate.salutation" :key="`salutation-${index}`" label="Salutation">
              <Input v-model.trim="item.value" />
            </Form-item>            

            <!--email-->
            <Form-item v-for="(item, index) in formValidate.emails" v-bind:key="index"
              label="Email" class="contact-composite" :class="{'email-label': item.default}"
              :prop="'emails.' + index + '.value'"
              :rules="ruleValidate.email">
              <div class="default-email-checkbox">
                <Checkbox
                  :label="item.value"
                  :value="item.default"
                  @on-change="checked => handleDefaultEmailChange(checked, item)">&nbsp;</Checkbox>
              </div>
              <Row class="rowItem" :key="`email-${index}`">
                <Col span="22">
                  <Input v-model.trim="item.value" @on-change="searchContact(index)">
                    <Select v-model="item.value_type" slot="append" style="width: 90px">
                      <Option value="work">Work</Option>
                      <Option value="personal">Personal</Option>
                      <!-- <Option value="dept">Dept</Option> -->
                      <Option value="others">Others</Option>
                    </Select>
                  </Input>
                </Col>
                <Col span="2" class="formHandle">
                  <Icon type="ios-add-circle-outline"
                        @click.native="handleAdd(index,'email')"
                        :class="{'disable':formValidate.emails.length > 3}"
                        v-if="index==0"
                        size="20"
                        class="handleIcon"></Icon>
                  <Icon type="ios-remove-circle-outline"
                        @click.native="handleRemove(index, formValidate.emails)"
                        v-else
                        size="20"
                        class="handleIcon"></Icon>
                </Col>
              </Row>
            </Form-item>

            <!--phone-->
            <Form-item label="Phone" prop="phone" class="contact-composite">
              <Row v-for="(item, index) in phoneDynamic.items"
                   class="rowItem"
                   :key="`phone-${index}`"
                   :class="{'spacing':index>0}">
                <Col span="22">
                <Input v-model.trim="item.value" @on-change="searchContact(index)">
                <Select v-model="item.value_type" slot="append" style="width: 90px">
                  <Option value="mobile">Mobile</Option>
                  <Option value="office">Office</Option>
                  <Option value="fax">Fax</Option>
                  <Option value="others">Others</Option>
                </Select>
                </Input>
                </Col>
                <Col span="2" class="formHandle">
                <Icon type="ios-add-circle-outline"
                      @click.native="handleAdd(index,'phone')"
                      :class="{'disable':phoneDynamic.items.length>3}"
                      v-if="index==0"
                      size="20"
                      class="handleIcon"></Icon>
                <Icon type="ios-remove-circle-outline"
                      @click.native="handleRemove(index,phoneDynamic.items)"
                      v-else
                      size="20"
                      class="handleIcon"></Icon>
                </Col>
              </Row>
            </Form-item>

            <!--im-->
            <Form-item label="IM" prop="Im" class="contact-composite">
              <Row v-for="(item, index) in imDynamic.items"
                   class="rowItem"
                   :key="`im-${index}`"
                   :class="{'spacing':index>0}">
                <Col span="22">
                  <Input v-model.trim="item.value" @on-change="searchContact(index)">
                  <Select v-model="item.value_type" slot="append" style="width: 90px">
                    <Option value="skype">Skype</Option>
                    <Option value="ice">ICE</Option>
                    <Option value="wechat">WeChat</Option>
                    <Option value="qq">QQ</Option>
                  </Select>
                  </Input>
                </Col>
                <Col span="2" class="formHandle">
                <Icon type="ios-add-circle-outline"
                      @click.native="handleAdd(index,'im')"
                      :class="{'disable':imDynamic.items.length>3}"
                      v-if="index==0"
                      size="20"
                      class="handleIcon"></Icon>
                <Icon type="ios-remove-circle-outline"
                      @click.native="handleRemove(index,imDynamic.items)"
                      v-else
                      size="20"
                      class="handleIcon"></Icon>
                </Col>
              </Row>
            </Form-item>

            <!--company-->
            <Form-item label="Company" prop="address_book_id">
              <autoInput :dataList="companies"
                         :category="'company'"
                         ref="companyInput"
                         placeholder="Select"
                         @selectedCompany="selectedCompany"
                         @searchCompanyDone="searchCompanyDone"
              />
            </Form-item>

            <!--Department-->
            <Form-item label="Department" prop="department_id">
              <autoInput :dataList="departments"
                         :category="'department'"
                         ref="companyDepartment"
                         :disable="departments.length == 0"
                         placeholder="Select"
                         @selectedDepartment="departmentSelected"
                         @searchDepartmentDone="searchDepartmentDone"
              />
            </Form-item>

            <!--job_title-->
            <Form-item label="Job Title" prop="job_title">
              <Input v-model.trim="formValidate.job_title"></Input>
            </Form-item>

            <!--Street Address-->
            <Form-item label="Street Address" prop="postal_address">
              <Input v-model.trim="formValidate.postal_address"></Input>
            </Form-item>

            <!--City-->
            <Form-item label="City" prop="city">
              <Input v-model.trim="formValidate.city"></Input>
            </Form-item>

            <!--state-->
            <Form-item label="State" prop="state">
              <Input v-model.trim="formValidate.state"></Input>
            </Form-item>

            <!--Postal code-->
            <Form-item label="Postal Code" prop="postal_code">
              <Input v-model.trim="formValidate.postal_code"></Input>
            </Form-item>

            <!--Country-->
            <Form-item label="Country" prop="country">
              <autoSelect :dataList="basicData.countries"
                          :label="'1'"
                          :pass="'0'"
                          v-model="formValidate.country_id"
                          placeholder="Select">
              </autoSelect>
            </Form-item>

            <!--Web/URL-->
            <Form-item label="Web/URL" prop="website">
              <Input v-model.trim="formValidate.website"></Input>
            </Form-item>

            <!--note-->
            <Form-item label="Notes" prop="note">
              <Input
                type="textarea"
                style="resize: vertical"
                v-model.trim="formValidate.note"
                :maxlength="3000"
              />
            </Form-item>

            <!--tags-->
            <Form-item label="Tags" prop="tags">
              <input-tag 
                :tags="tags"
                :canAddNew="!definedTagsEnabled || p_tags"
                :getSuggestList="getTags"
                validate="tag"
              />
            </Form-item>
            <add-to-group ref="addToGroup" :contactName="formValidate.name" />
          </Form>
        </div>

        <!-- contact suggestion -->
        <div class="suggest-container">
          <suggest 
            ref="suggest" 
            v-if="contactSuggestionShow" 
            @fill="getUser" 
            :category="'contacts'"
            :from="formValidate" 
            :emailArr="formValidate.emails" 
            :phoneArr="phoneDynamic.items"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script type="text/ecmascript-6">
  import api from '../../../fetch/api.js';
  import {mapState, mapGetters} from 'vuex';
  import suggest from './suggest';
  import InputTag from '@/pages/components/InputTag.vue';
  import autoInput from './autoInput.vue';
  import autoSelect from '../../components/autoSelect.vue';
  import { serialize } from 'object-to-formdata';
  import utils from '@/utils'
  import avatar from '@/pages/components/avatar.vue'
  import AddToGroup from './AddToGroup.vue'
  import { of } from 'rxjs';
  import { addContactMixin } from '@/mixins';

  export default {
    data() {
      const validateEmail = (rule, value, callback) => {
        const isValid = !value || utils.filter.validators.email.test(value);
        !isValid && callback(new Error('Please enter valid email address.'))
        isValid && callback();
      }
      return {
        addLoading: false,
        pageName: 'Add new person',
        imgPreview: '',
        formValidate: {
          name: '',
          first_name: '',
          last_name: '',
          level: 'contact',
          avatar: {},
          company_name: '',
          department_name: '',
          job_title: '',
          postal_address: '',
          city: '',
          state: '',
          postal_code: '',
          country_id: 0,
          website: '',
          note: '',
          emails: [{'item_type': 'email', 'value': '', 'value_type': 'work', 'default': true}],
          salutation: [{'item_type': 'salutation', 'value': '', 'value_type': '', 'default': true}],
        },
        ruleValidate: {
          name: [{ required: true, message: 'Full name can not be empty.', trigger: 'blur' }],
          email: { validator: validateEmail, trigger: 'blur' }
        },
        phoneDynamic: {
          items: [
            {'item_type': 'phone', 'value': '', 'value_type': 'mobile', 'default': true},
          ]
        },
        imDynamic: {
          items: [
            {'item_type': 'im', 'value': '', 'value_type': 'skype', 'default': true},
          ]
        },
        companies: [],
        departments: [],
        country: '',
        tags: []
      }
    },
    mixins: [addContactMixin],
    created () {
      !this.contactId && this.createInit();
      this.contactId && this.editInit();
    },
    computed: {
      ...mapState([
        'user',
        'basicData',
        'configuredTags',
      ]),
      ...mapGetters(["contactPreUpdate", "setting_company", "userInfo"]),

      isAdmin() {
        return this.userInfo.user.user_type === "admin";
      },
      p_tags() {
        return this.isAdmin || this.checkPermission("add_edit_delete_configured_tags");
      },
      definedTagsEnabled() {
        return this.setting_company.limit_hashtag == '1';
      },
      contactSuggestionShow() {
        return !this.contactId && this.setting_company.allow_chartdesk_contacts_directory == '1'
      },
      contactId() {
        return this.$route.name === 'editContact' ? this.$route.params.id : undefined
      }
    },
    methods: {
      onChangeName() {
        const firstName = this.formValidate.first_name || '';
        const lastName = this.formValidate.last_name || '';
        
        this.formValidate.name = [firstName, lastName].join(' ').trim();
      },
      handleDefaultEmailChange(checked, item) {
        this.formValidate.emails.forEach(e => e.default = false)
        item.default = checked;
      },
      getDefaultEmails() {
        const email = !this.contactId ? this.$route.query.email : ''
        return [{'item_type': 'email', 'value': email || '', 'value_type': 'work', 'default': true}];
      },
      getDefaultSalutation() {
        return [{'item_type': 'salutation', 'value': '', 'value_type': '', 'default': true}];
      },
      searchCompanyDone(companies) {
        if(!companies)
          return;
          
        const isEdit = !!this.contactId;
        const matchResult = companies[0] && this.$refs.companyInput.searchKey == companies[0].name;
        const searchKeyBinded = this.$route.query.company_name == this.$refs.companyInput.searchKey;
        !isEdit && searchKeyBinded && matchResult && this.selectedCompany(companies[0]);
        isEdit
          && matchResult 
          && companies[0].departments 
          && companies[0].departments.length > 0
          && companies[0].departments.findIndex(d => d.name == this.$refs.companyDepartment.searchKey) > -1
          && (this.departments = companies[0].departments);
      },
      searchDepartmentDone(departments) {
        if(!departments)
          return;
        const isEdit = !!this.contactId;
        const matchResult = departments[0] && this.$refs.companyDepartment.searchKey == departments[0].name;
        const searchKeyBinded = this.$route.query.department_name == this.$refs.companyDepartment.searchKey;
        !isEdit && searchKeyBinded && matchResult && this.populateAddress(departments[0]);
      },
      selectedCompany(item) {
        if(!item) {
          this.resetDepartment();
          return;
        }
        if(!item.departments || item.departments.length == 0) {
          this.resetDepartment();
          this.populateAddress(item);
          return;
        }
        
        this.departments = item.departments;
        const existedDepartment = this.departments.findIndex(d => d.name == this.$refs.companyDepartment.searchKey) > -1;
        if((!this.$refs.companyDepartment.searchKey || !existedDepartment) && this.$route.query.department_name)
          this.$refs.companyDepartment.searchKey = this.departments[0].name || '';

        const combinedAddress = this.getCombineAddress(this.departments[0], item);
        this.populateAddress(combinedAddress)
      },
      departmentSelected(item) {
        this.populateAddress(item);
      },
      resetDepartment() {
        this.$refs.companyDepartment.searchKey = '';
        this.departments = [];
      },
      getCombineAddress(department, company) {
        return {
          postal_address: department.postal_address || company.postal_address,
          city: department.city || company.city,
          state: department.state || company.state,
          postal_code: department.postal_code || company.postal_code,
          country_id: department.country_id || company.country_id,
          website: department.website || company.website
        }
      },
      populateAddress(address) {
        this.formValidate.postal_address = address.postal_address
        this.formValidate.city = address.city
        this.formValidate.state = address.state
        this.formValidate.postal_code = address.postal_code
        this.formValidate.country_id = address.country_id
        this.formValidate.website = address.website
      },

      handleBeforeUpload(file){
        const imgSize = 1024000 * 2; // max img size
        if (file.size > imgSize) {
          this.$Message.warning('The file is too big, no more than 2M');
          return
        }
        if (!file.type.match(/^image\/(gif|jpe?g|a?png|svg|webp|bmp|vnd\.microsoft\.icon)/i)) {
          this.$Message.warning('Format is not correct, please upload a JPG or PNG format images.');
          return
        }
        this.formValidate.avatar = file;
        this.readFile(file, this.displayImg);
        return Promise
      },
      displayImg(file){
        let that = this;
        let reader = new FileReader();
        reader.onload = function (e) {
          that.imgPreview = e.target.result;
        };
        reader.readAsDataURL(file.file);
      },
      readFile(file, callback){
        let tempfile = {
          file: file,
          size: file.size,
          name: file.name,
        };
        callback(tempfile);
      },
      generateParams() {
        this.addLoading = true;
        const newSelectedName = utils.removeSearchHtml(this.$refs.companyInput.searchKey);
        const newSelectedId = this.$refs.companyInput.searchId || this.$route.query.company_id;

        if (this.formValidate.company_name !== newSelectedName) {
          this.formValidate.company_name = newSelectedName;
          if (newSelectedName && newSelectedId) {
            this.formValidate.address_book_company_id = +newSelectedId
          } else {
            delete this.formValidate.address_book_company_id
          }
        }
        this.formValidate.department_name = utils.removeSearchHtml(this.$refs.companyDepartment.searchKey);
        this.formValidate.name = utils.removeSearchHtml(this.formValidate.name);
        this.formValidate.level = 'contact';
        let contact_items = [
          ...this.formValidate.emails, 
          ...this.phoneDynamic.items, 
          ...this.imDynamic.items,
          ...this.formValidate.salutation
        ];
        let address_book = Object.assign({}, this.formValidate);
        address_book.shared_groups = this.$refs.addToGroup.selectedSharedGroups.map(g => g.id)
        address_book.my_groups = this.$refs.addToGroup.selectedMyGroups.map(g => g.id)
        delete address_book.emails;
        delete address_book.salutation;
        return serialize({
          'address_book': address_book,
          'contact_items': JSON.stringify(contact_items),
          'tags': this.tags,
        });
      },
      handleSubmit (name) {
        const companyValid = !this.$refs.companyInput.error;
        const departmentValid = !this.$refs.companyDepartment.error;
        if(!companyValid || !departmentValid)
          return this.$Message.warning("Please check your form");

        this.$refs[name].validate((valid) => {
          if(!valid)
            return this.$Message.warning("Please check your form");

          const params = this.generateParams();
          this.contactId && api
            .update_contact(this.contactId, params)
            .then((res) => {
              this.$Message.success('Edited contact successfully.');
              this.$router.push(this.contactPreUpdate.currentPath || '/contacts')
              this.addLoading = false;
            })
            .catch((error) => {
              console.log(error);
              this.addLoading = false;
            });

          !this.contactId && api
            .create_address_book(params)
            .then((res) => {
              if(this.initialData) {
                this.$emit('done', res.address_book.id)
                return
              }
              this.$Message.success('Added contact successfully.');
              this.contactPreUpdate.currentPath 
                ? this.$router.push(this.contactPreUpdate.currentPath) 
                : this.$router.push('/contacts?tab=contact')
            })
            .catch((error) => {
              console.log(error);
              this.addLoading = false;
            });
        })
      },
      editInit() {
        this.addLoading = true;
        api.edit_contact(this.contactId).then((res) => {
          this.$refs.companyInput.searchKey = res.address_book.company || '';
          setTimeout(() => {
            this.$refs.companyDepartment.searchKey = res.address_book.department || '';
            delete res.address_book.department;
          }, 500);
          if (res.address_book.phones.length > 0) this.phoneDynamic.items = res.address_book.phones;
          if (res.address_book.ims.length > 0) this.imDynamic.items = res.address_book.ims;
          this.tags = res.address_book.tags;
          this.imgPreview = res.address_book.avatar_url;

          res.address_book.company_name = res.address_book.company

          delete res.address_book.company;
          delete res.address_book.company_logo;
          delete res.address_book.is_edited;
          delete res.address_book.is_new;
          delete res.address_book.id;
          delete res.address_book.phones;
          delete res.address_book.ims;
          delete res.address_book.tags;
          delete res.address_book.avatar_url;
          
          this.formValidate = {...res.address_book};
          this.pageName = `${this.formValidate.level == 'non_contact' ? 'Add New' : 'Edit'} Person`;
          (!this.formValidate.emails || this.formValidate.emails.length == 0) 
            && (this.formValidate.emails = this.getDefaultEmails());
          (!this.formValidate.salutation || this.formValidate.salutation.length == 0) 
            && (this.formValidate.salutation = this.getDefaultSalutation());
          this.addLoading = false;
        }).catch((error) => {
          console.log(error);
          this.addLoading = false;
        });
      },

      createInit() {
        Object.assign(this.$data, this.$options.data())
        this.formValidate.emails = this.getDefaultEmails();
        this.$refs.companyInput && (this.$refs.companyInput.searchKey = '');
        this.$refs.companyInput && (this.$refs.companyDepartment.searchKey = '');
      },
      
      searchContact (index) {
        if(!this.contactSuggestionShow)
          return

        if (this.contactId || index !== 0) 
          return;
        
        if (!this.formValidate.name && !this.formValidate.emails[0].value && !this.phoneDynamic.items[0].value)
          this.$refs.suggest.clearSuggest()

        if (this.formValidate.name.length < 3 && this.formValidate.emails[0].value.length < 3 && this.phoneDynamic.items[0].value.length < 3)
          return

        const params = {
          'name': this.formValidate.name,
          'email': this.formValidate.emails[0].value,
          'phone': this.phoneDynamic.items[0].value,
          'level': 'contact',
        };
        this.$refs.suggest.init(params)
      },
      
      getUser (obj) {
        const defaultEmails = this.getDefaultEmails();
        this.formValidate = {
          name: obj.name,
          first_name: obj.first_name,
          last_name: obj.last_name,
          level: 'contact',
          avatar: {},
          job_title: obj.job_title,
          postal_address: obj.postal_address,
          city: obj.city,
          state: obj.state,
          postal_code: obj.postal_code,
          country_id: obj.country_id,
          website: obj.website,
          note: obj.note,
          emails: defaultEmails,
          salutation: this.getDefaultSalutation()
        };
//        company->departments
        this.$refs.companyInput.searchKey = obj.company || '';
        setTimeout(() => {
          this.$refs.companyDepartment.searchKey = obj.department || '';
        }, 500);

//        email/phone/im/tags
        if (obj.emails.length > 0) this.formValidate.emails = obj.emails.slice(0);
        if (obj.phones.length > 0) this.phoneDynamic.items = obj.phones.slice(0);
        if (obj.ims.length > 0) this.imDynamic.items = obj.ims.slice(0);
        if (obj.salutation.length > 0) this.formValidate.salutation = obj.salutation.slice(0);

        this.tags = obj.tags.slice(0);

        setTimeout(() => {
          this.$refs.formValidate.validate()
        }, 100)
      },

      handleAdd(index, type){
        if (type === 'email') {
          if (this.formValidate.emails.length === 4) {
            return
          }
          let email = { item_type: 'email', value: '', value_type: 'work', default: false };
          this.formValidate.emails.push(email)
        }
        if (type === 'phone') {
          if (this.phoneDynamic.items.length === 4) {
            return
          }
          let phone = {'item_type': 'phone', 'value': '', 'value_type': 'mobile', 'default': false};
          this.phoneDynamic.items.push(phone)
        }
        if (type === 'im') {
          if (this.imDynamic.items.length === 4) {
            return
          }
          let im = {'item_type': 'im', 'value': '', 'value_type': 'skype', 'default': false};
          this.imDynamic.items.push(im)
        }

      },
  
      handleRemove (index, arr) {
        arr.splice(index, 1);
      },
      getTags(name) {
        if(this.setting_company.limit_hashtag == '1') {
          const filteredList = this.configuredTags.filter(t => !!t.includes(name));
          return of(filteredList);
        }
        return api.suggest_tags({
          tagable_type: "AddressBook",
          key: name
        });
      }
    },
    watch: {
      '$route' () {
        this.contactId && this.editInit();
        !this.contactId && this.createInit();
      },
      'formValidate.name'(){
        this.searchContact(0);
      }
    },
    components: {
      InputTag,
      suggest,
      autoInput,
      autoSelect,
      avatar,
      AddToGroup
    }
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  .addContact {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .container {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .contain-wrapper {
        display: flex;
        flex: 1;
        overflow: auto;
        overflow: overlay;
        margin-bottom: 20px;
        .form-contain {
          flex: 6;
          padding: 24px;
          padding-bottom: 100px;
          .imgUpload {
            display: flex;
            .avatar {
              display: inline-block;
              margin-right: 24px;
            }
          }
          .handleUpload {
            display: inline-block;
            vertical-align: top;
            height: 60px;
            span {
              font-size: 12px;
              color: #828d9d;
            }
          }
          .contact-composite {
            .default-email-checkbox {
              position: absolute;
              top: 0px;
              left: -35px;
            }
            .rowItem {
              display: flex;
              align-items: center;

              &.spacing {
                margin-top: 10px;
              }
              .formHandle {
                .handleIcon {
                  cursor: pointer;
                  float: right;
                  color: var(--primary-color);
                  &.disable {
                    cursor: not-allowed;
                    color: var(--text-color-disable);
                  }
                }
              }
            }

          }
        }
        .suggest-container {
          flex: 5;
        }
      }
    }
  }

</style>
