<template>
  <div class="container-wrapper table-wrapper email-wrapper">
    <mailTabs ref="mailTabs" @dragDone="mailDragDone" v-show="!(previewExpanded || isOpeningInappWindow)"/>
    <section class="mail container">
      <!--loading-->
      <div class="loading" v-show="tableLoading">
        <Spin size="large" fix></Spin>
      </div>
      <!--actions-->
      <header class="mail-toolbar" v-show="!(previewExpanded || isOpeningInappWindow)">
        <div class="left">
          <div v-if="isShowAssignmentTabs" class="assignment-tabs">
            <template v-for="(tab, index) in Object.values(assignmentTabs)">
              <div
                v-if="!tab.hide"
                class="assignment-tab-button"
                :key="index"
                @click.capture="($event) => handleClickOpenButton($event, tab)"
              >
                <Select v-if="tab.name === 'open'"
                  :class="{ 'highlight-btn': currentTabName == tab.name }"
                  size="small"
                  :style="'min-width: 80px;'"
                  class="select-no-border tab"
                  @on-select="(selectedMode) => onAssignTabClick(tab,selectedMode)"
                  v-model="assignTabOpenMode"
                >
                  <Option value="all">All</Option>
                  <Option value="open">Open</Option>
                  <Tooltip transfer placement="right" content="Hide mails assigned to others" :style="'width: 100%;'">
                    <Option value="focused">Focused</Option>
                  </Tooltip>
                </Select>
                <div
                  v-if="tab.name !== 'open'"
                  class="tab"
                  :class="{ 'highlight-btn': currentTabName == tab.name }"
                  @click="onAssignTabClick(tab)"
                >
                  {{ tab.label }}
                </div>
              </div>
            </template>
          </div>
          <Select v-if="!(($route_box() && $route_box().toLowerCase() == 'draft') || ($route_box() && $route_box().toLowerCase() == 'sent' && $route_status() === 'pending'))"
            size="small"
            class="select-no-border item selected-list__btn"
            :class="{active: isChangedReadAndUnread}"
            @on-change="readChange"
            v-model="selectedList"
          >
            <Option value="all" selected="all"> Read & Unread </Option>
            <Option value="read"> Read </Option>
            <Option value="unread"> Unread </Option>
          </Select>
          <div v-if="!($route_folder_id() && $route_folder_type() === 'normal')" class="item">
            <Checkbox v-model="hideFiled" @on-change="onHideFiledChange">
              <span class="checkbox-label">Hide filed</span>
            </Checkbox>
          </div>
          <div class="item">
            <Checkbox v-model="showHiddenEmail" @on-change="onShowHidden">
              <span class="checkbox-label">Show hidden</span>
            </Checkbox>
          </div>
          <div v-if="$route_folder_id() && p_enabled_assignment" class="item">
            <Checkbox v-model="hideAssigned" @on-change="onHideAssignedChange">
              <span class="checkbox-label">Hide assigned</span>
            </Checkbox>
          </div>
          <button 
            class="filter-button highlight-btn"
            :class="{'active': showFilter}"
            @click="toggleFilter"
          >
            <svg-sprite
              :width="18"
              :height="18"
              name="mt_filter"
            />
            {{ showFilter ? 'Clear all' : 'Filter' }}
          </button>
          <DropdownButton
            v-if="trigonalCapabilities && trigonalCapabilities.length > 0"
            :items="trigonalCapabilities"
            :disabled="(!checkGroup || checkGroup.length === 0) && !selectedRow"
            :loading="trigonalLoading"
            @buttonClick="onTrigonalCapClick"
            @dropdownClick="onTrigonalCapClick"
          />
          <Tooltip :content="bookmark ? 'Go to bookmark' : 'Bookmark an email first'"
            class="item actionable-icon-tooltip"
          >
            <a @click="bookmark ? gotoBookmark() : null" class="bookmark-icon-actionable" :class="{'disabled': !bookmark}">
              <svg-sprite
                :width="12"
                :height="17"
                viewBox="0 0 12 17"
                class="bookmark-icon" :class="bookmark ? 'mark' : 'unmark'"
                :name="bookmark ? 'bookmark' : 'bookmark_outline'"
              />
            </a>
          </Tooltip>
        </div>
        <div class="right">
          <div v-if="checkGroup.length > 0" class="selection-actions">
            <Tooltip v-if="$route_folder_id() && folder && folder.folder_type==='normal'" content="Remove from folder" class="actionable-icon-tooltip">
              <a @click="removeEmailsFromfolder" class="actionable-icon">
                <svg-sprite :width="19" :height="16" viewBox="0 0 19 16" name="remove_from_folder" />
              </a>
            </Tooltip>
            <Tooltip v-if="isShowStar" content="Star email" class="actionable-icon-tooltip">
              <a @click="onStarClick(true, checkGroup)" class="actionable-icon">
                <svg-sprite :width="22" :height="22" viewBox="0 0 22 22" name="mt_star_highlight" />
              </a>
            </Tooltip>
            <Tooltip v-if="isShowUnstar" content="Unstar email" class="actionable-icon-tooltip">
              <a @click="onStarClick(false, checkGroup)" class="actionable-icon">
                <svg-sprite :width="22" :height="22" name="mt_star" />
              </a>
            </Tooltip>
            <Tooltip content="Not spam" v-if="$route_box() && $route_box().toLowerCase() === 'spam'" class="actionable-icon-tooltip">
              <a @click="onUnSpamEmails(checkGroup)" class="actionable-icon">
                <i class="iconfont-not-spam"/>
              </a>
            </Tooltip>
            <Tooltip v-if="!isSpamList" content="Tag email" class="actionable-icon-tooltip">
              <a @click="openTagEml" class="actionable-icon">
                <span class="iconfont-tag" />
              </a>
            </Tooltip>
            <Tooltip v-if="!isSpamList" content="Forward as attachment" class="actionable-icon-tooltip">
              <a @click="forwardMail" class="actionable-icon">
                <i class="iconfont-attachment" />
              </a>
            </Tooltip>
            <Tooltip v-if="!isSpamList && $route_box() && $route_box().toLowerCase() == 'sent' && $route_status()==='pending'" content="Abort" class="actionable-icon-tooltip">
              <div class="actionable-icon" @click="abortSend">
                <i class="iconfont ch-icon-forbidden"></i>
              </div>
            </Tooltip>
            <Tooltip content="Assign to" class="actionable-icon-tooltip">
              <assignment
                class="actionable-icon"
                :subjects="checkGroupEmails"
                :value="checkGroupEmails.length == 1 ? checkGroupEmails[0] && checkGroupEmails[0].assignment_ids : []"
                :members-placement="'bottom'"
                :show-tooltip="false"
              >
                <svg-sprite
                  name="assign_to"
                  :width="20"
                  :height="20"
                  viewBox="0 0 20 23"
                />
              </assignment>
            </Tooltip>
            <Tooltip v-if="!isSpamList" content="File to folder" class="actionable-icon-tooltip">
              <div class="actionable-icon" @click="toggleEmailFiling" ref="iconEmailFiling">
                <svg-sprite :width="19" :height="16" viewBox="0 0 19 16" name="file_to_folders" />
              </div>
            </Tooltip>
            <EmailFiling
              ref="emailFiling"
              v-if="emailFilingShown"
              class="email-filing"
              :style="{ top: `${emailFilingPosition.top}px`, left: `${emailFilingPosition.left}px` }"
              :emails="checkGroupEmails"
              @done="onEmailFiled"
            />
            <div v-if="emailFilingShown" class="email-filing-backdrop" @click="closeEmailFiling"></div>
            <Tooltip
              v-if="!isSpamList && !isOutboxList && !isDraftList && !isSentList && !isArchivedList && p_mark_spam"
              content="Mark as spam"
              class="actionable-icon-tooltip"
            >
              <div class="actionable-icon" @click="onMarkAsSpam()">
                <i class="iconfont-spam"></i>
              </div>
            </Tooltip>
            <Tooltip v-if="!!p_enabled_archive && !isArchivedList" content="Archive email" class="actionable-icon-tooltip">
              <a @click="confirmArchiveMailShow=true" class="actionable-icon">
                <i class="iconfont-archived" />
              </a>
            </Tooltip>
            <Tooltip v-if="isArchivedList" content="Unarchive email" class="actionable-icon-tooltip">
              <a @click="confirmArchiveMailShow=true" class="actionable-icon">
                <i class="iconfont-archived" />
              </a>
            </Tooltip>
            <Tooltip content="Delete email" v-if="p_delete_mail" class="actionable-icon-tooltip">
              <a @click="confirmDeleteMailShow=true" class="actionable-icon">
                <i class="iconfont-trash" />
              </a>
            </Tooltip>
          </div>
          <Dropdown placement="bottom-end" :trigger="'click'" class="more actionable-icon">
            <i class="iconfont-more-vertical"></i>
            <Dropdown-menu slot="list">
              <Dropdown-item @click.native="showMailPreviewBody()">{{previewMailBody ? 'Hide' : 'Display'}} text preview</Dropdown-item>
              <hr class="devider"/>
              <Dropdown-item v-if="$route_folder_id() && folder && folder.folder_type==='normal'" @click.native="removeEmailsFromfolder" :class="{unActive : checkGroup.length == 0}">Remove from folder</Dropdown-item>
              <Dropdown-item v-if="p_edit_share" @click.native="editFolder">Edit folder</Dropdown-item>
              <Dropdown-item v-if="$route_folder_id()" @click.native="showFolderInfo">Folder details</Dropdown-item>
              <Dropdown-item v-if="p_delete_share" @click.native="confirmShow=true">Delete folder</Dropdown-item>
              <hr v-if="$route_folder_id()" class="devider"/>
              <Dropdown-item @click.native="checkGroup.length == 0 ? null : onUnSpamEmails(checkGroup)" :class="{unActive : checkGroup.length == 0}" v-if="$route_box() && $route_box().toLowerCase() == 'spam'">Not spam</Dropdown-item>
              <Dropdown-item v-if="!isSpamList" @click.native="checkGroup.length == 0 ? null : openTagEml()" :class="{unActive : checkGroup.length == 0}">Tag emails</Dropdown-item>
              <Dropdown-item v-if="!isSpamList" @click.native="checkGroup.length == 0 ? null : forwardMail()" :class="{unActive : checkGroup.length == 0}">Forward as attachment</Dropdown-item>
              <Dropdown-item v-if="!!p_enabled_archive && !isArchivedList" @click.native="checkGroup.length == 0 ? null : confirmArchiveMailShow=true" :class="{unActive : checkGroup.length == 0}">Archive emails</Dropdown-item>
              <Dropdown-item v-if="isArchivedList" @click.native="checkGroup.length == 0 ? null : confirmArchiveMailShow=true" :class="{unActive : checkGroup.length == 0}">Unarchive emails</Dropdown-item>
              <Dropdown-item v-if="p_delete_mail" @click.native="checkGroup.length == 0 ? null : confirmDeleteMailShow=true" :class="{unActive : checkGroup.length == 0}">Delete emails</Dropdown-item>
              <Dropdown-item @click.native="checkGroupEmails.some(e => !e.read) ? readEmails() : null" :class="{unActive : !checkGroupEmails.some(e => !e.read)}">Mark as read</Dropdown-item>
              <Dropdown-item v-if="p_mark_as_unread" 
                :class="{unActive : !canMarkAsUnread}"
                @click.native="canMarkAsUnread ? onMarkAsUnread(checkGroup) : null" 
              >
                Mark as unread
              </Dropdown-item>
              <Dropdown-item v-if="checkGroupEmails.some(el => !el.is_hide)" @click.native="checkGroup.length == 0 ? null: markHidden()" :class="{unActive : checkGroup.length == 0}">Mark as hidden</Dropdown-item>
              <Dropdown-item v-if="checkGroupEmails.some(el => el.is_hide)" @click.native="checkGroup.length == 0 ? null: markUnHide()" :class="{unActive : checkGroup.length == 0}">Unhide</Dropdown-item>
              <Dropdown-item v-if="p_enabled_assignment"
                @click.native="checkGroup.length == 0 ? null : changeAssignmentStatus()"
                :class="{unActive : checkGroup.length == 0}">
                {{ currentTabName == assignmentTabs.CLOSED.name ? 'Reopen' : 'Mark as closed' }}
              </Dropdown-item>
            </Dropdown-menu>
          </Dropdown>
        </div>
      </header>
      <MailFilter v-if="showFilter" 
        v-show="!(previewExpanded || isOpeningInappWindow)" 
        ref="mailFilterRef" 
        :currentAssignTo="currentAssignTo"
        @on-change="onMailFilterChange" 
      />
      <div class="main-content-wrapper" :class="{'preview-vertical': previewVertical && (lockedPlane || previewing)}">
        <InappMailWindow v-for="s in inappMailState" :key="s.key" :inAppState="s" />
        <!-----------------contain------------------>
        <div class="contain-wrapper"
        :class="{'half-size-list': previewing}"
        ref="wrapper">
          <div class="table-contain ivu-table-with-fixed-top" :style="tableHeight">
            <right-menu-table
              :leftPos="rightMenuLeftPos"
              :topPos="rightMenuTopPos"
              :columnObj="columnObj"
              @changeColumn="handleChangeColumn"
              ref="rightMenuTable" />
            <!--table header-->
            <div class="ivu-table-header" v-show="!(previewVertical && (lockedPlane || previewing))">
              <table class="ui table mails-table-header" ref="mailTable">
                <thead class="fixedWidth">
                  <tr ref="mailTableHeader" @contextmenu.prevent="customizeTableColumn($event)">
                    <th class="mail-icons">
                      <div class="checkBox">
                        <Checkbox :value="isSelectAll" @on-change="onSelectAll" />
                        <Tooltip
                          v-if="checkGroup.length > 0"
                          content="Clear selection"
                          :transfer="true" placement="top-start"
                          class="selected-count"
                        >
                          <p @click="clearCheck">{{checkGroup.length}} selected</p>
                        </Tooltip>
                      </div>
                      <div :style="{visibility: isSpamList ? 'hidden' : 'visible'}" class="star-box"></div>
                      <div :style="{width: 'var(--status-td-width)'}"></div>
                      <div :style="{width: 'var(--file-td-width)'}"></div>
                    </th>
                    <resizable-table-header
                      v-if="columnObj['Date/Time']"
                      class="table-time"
                      column-name="table-time"
                      id="table-time"
                    >
                      Date
                      <span class="sort" :class="{'active': sortField === 'received_at'}" @click="toggleSort('received_at')">
                        <Icon
                          type="md-arrow-dropdown"
                          v-if="sortField!=='received_at' || sortField==='received_at' && sortMethod==='desc'"
                        />
                        <Icon type="md-arrow-dropup" v-if="sortField==='received_at' && sortMethod==='asc'" />
                      </span>
                      <svg-sprite
                        :width="22"
                        :height="22"
                        name="mt_filter"
                        class="mail-filter-icon"
                        @click.native="onFilterClick('received_at')"
                      />
                    </resizable-table-header>
                    <resizable-table-header
                      v-if="columnObj['From']"
                      class="table-from"
                      column-name="table-from"
                      id="table-from"
                    >
                      From
                      <svg-sprite
                        :width="22"
                        :height="22"
                        name="mt_filter"
                        class="mail-filter-icon"
                        @click.native="onFilterClick('from')"
                      />
                    </resizable-table-header>
                    <resizable-table-header
                      v-if="columnObj['To']"
                      class="table-to"
                      column-name="table-to"
                      id="table-to"
                    >
                      To
                      <svg-sprite
                        :width="22"
                        :height="22"
                        name="mt_filter"
                        class="mail-filter-icon"
                        @click.native="onFilterClick('to')"
                      />
                    </resizable-table-header>
                    <resizable-table-header
                      ref="resizableHeader"
                      class="table-subject"
                      column-name="table-subject"
                      id="table-subject"
                    >
                      Subject
                      <svg-sprite
                        :width="22"
                        :height="22"
                        name="mt_filter"
                        class="mail-filter-icon"
                        @click.native="onFilterClick('subject')"
                      />
                    </resizable-table-header>
                    <resizable-table-header
                      v-if="columnObj['Mailbox']"
                      ref="resizableHeader"
                      class="table-mailbox"
                      column-name="table-mailbox"
                      id="table-mailbox">Mailbox</resizable-table-header>
                    <resizable-table-header
                      v-if="columnObj['Assignment'] && !isSpamList && p_enabled_assignment"
                      class="table-assignment"
                      column-name="table-assignment"
                      id="table-assignment"
                    >
                      <i class="iconfont-user" />
                      <svg-sprite
                        :width="22"
                        :height="22"
                        name="mt_filter"
                        class="mail-filter-icon"
                        @click.native="onFilterClick('assignees')"
                      />
                    </resizable-table-header>
                    <resizable-table-header
                      v-if="columnObj['Tag']"
                      class="table-tag"
                      column-name="table-tag"
                      id="table-tag">
                      <i class="iconfont-tag" />
                      <svg-sprite
                        :width="22"
                        :height="22"
                        name="mt_filter"
                        class="mail-filter-icon"
                        @click.native="onFilterClick('tag')"
                      />
                    </resizable-table-header>
                  </tr>
                </thead>
              </table>
            </div>
            <div v-if="dateIndicatorShown" class="datetime-filter-indicator">
              <span>{{ dateShortcut || (filter && filter.received_at) }}</span>
              <span class="ic-remove" @click="onHideDateIndicator"><Icon type="md-close" /></span>
            </div>
            <!--table body-->
            <div class="ivu-table-body home-page-table-mail"
              tabindex="-1"
              ref="tableWrapper"
            >
              <table ref="table"
                class="ui table mails-table"
                :class="{'include-sub-row': previewMailBody}"
              >
                <RecycleScroller
                  ref="mailsTableScroller"
                  class="mails-table-scroller"
                  :items="filterList"
                  :item-size="rowHeight"
                  key-field="id"
                >
                <template #before>
                  <div v-if="loadingPrevious" class="loader prev-loader"></div>
                </template>
                <template v-slot="{ item, index }">
                  <Checkbox-group v-model="checkGroup" @on-change="checkGroupChange(index)">
                    <email-list-vertical
                      v-if="previewVertical && (previewing || lockedPlane)"
                      :item="item"
                      :index="index"
                      :checkGroup="checkGroup"
                      :columnObj="columnObj"
                      :draggable="!isSpamList"
                      :previewMailBody="previewMailBody"
                      :id="`email-${item.id}`"
                      :ref="`emailRow${item.id}`"
                      :class="{
                        'row-highlight':
                          (selectedRow && selectedRow.id == item.id) ||
                          (bookmark && bookmark.email_id == item.id) ||
                          checkGroup.includes(item.id),
                        hasRead: item.read,
                        failed: item.status === 'failed',
                      }"
                      :bookmark="bookmark"
                      :p_enabled_assignment="p_enabled_assignment"
                      @email-shift-click="onEmailShiftClick(item, index)"
                      @click-current-row="clickCurrentRow(item, index)"
                      @dblclick-current-row="dblclickCurrentRow(item, index)"
                      @item-right-click="event=>onItemRightClick(event, item, index)"
                      @drag-start="event=>onDragStart(index, event)"
                      @drag-end="event=>onDragEnd(event)"
                      @email-click="onEmailClick(index)"
                      @star-click="(a,b)=>onStarClick(a,b)"
                      @open-report="(a,b)=>openReport(a,b)"
                      @open-folder="onOpenFolderPoptipMenu"
                      @bookmark="onBookmark(item)"
                    />
                    <email-list-horizontal
                      v-else
                      :item="item"
                      :index="index"
                      :checkGroup="checkGroup"
                      :columnObj="columnObj"
                      :draggable="!isSpamList"
                      :previewMailBody="previewMailBody"
                      :p_enabled_assignment="p_enabled_assignment"
                      :id="`email-${item.id}`"
                      :ref="`emailRow${item.id}`"
                      :class="{
                        'row-highlight':
                          (selectedRow && selectedRow.id == item.id) ||
                          (bookmark && bookmark.email_id == item.id) ||
                          checkGroup.includes(item.id),
                        hasRead: item.read,
                        failed: item.status === 'failed',
                      }"
                      :bookmark="bookmark"
                      @email-shift-click="onEmailShiftClick(item, index)"
                      @click-current-row="clickCurrentRow(item, index)"
                      @dblclick-current-row="dblclickCurrentRow(item, index)"
                      @item-right-click="event=>onItemRightClick(event, item, index)"
                      @drag-start="event=>onDragStart(index, event)"
                      @drag-end="event=>onDragEnd(event)"
                      @email-click="onEmailClick(index)"
                      @star-click="(a,b)=>onStarClick(a,b)"
                      @open-report="(a,b)=>openReport(a,b)"
                      @open-folder="onOpenFolderPoptipMenu"
                      @bookmark="onBookmark(item)"
                    />
                  </Checkbox-group>
                </template>
                <template #after>
                  <div v-if="mailList.length == maximumLoadedEmails" class="exceed-limit">You have reached the limit of emails loaded. Please use search filters instead.</div>
                </template>
                </RecycleScroller>
              </table>
              <div class="more-loader">
                <div v-if="loadingMore" class="loader"></div>
              </div>
              <template v-if="filterList.length == 0 && !tableLoading">
                <div v-if="!showFilter && $route_folder_id()">
                  <empty :addRule="editFolder" :pEdit="p_edit_share" />
                </div>
                <div v-else class="no-search-result">
                  <img v-if="!tableLoading" src="../../assets/images/no-data.svg" alt="No data">
                  <div class="NoResultText" v-if="!tableLoading">
                    No Matching Results
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <!--Dragging-->
        <div class="dragImg" ref="dragImg">
          <div class="firstImg">
            <div class="dragInfo">
              <span>{{dragTitle || 'No Subject'}}</span>
            </div>
            <div class="badge">
              <Badge :count="checkGroup.length" type="primary">
                <a href="#" class="demo-badge"></a>
              </Badge>
            </div>
          </div>
          <div class="secondImg"></div>
        </div>
        <mailDetail ref="mailDetail"
          v-if="previewing"
          :selectedEmail="selectedRow"
          :previewEmailId="selectedRow && selectedRow.id"
          :highlights="highlights"
          :singleClick="singleClick"
          :class="{expandedPreview}"
          :style="{zIndex: previewExpanded && !isOpeningInappWindow ? 1000 : 10}"
          @close="closePreviewDetail"
          @changeDirection="data => onChangeDirection(data)"
          @onResizeDone="onPreviewResizeDone"
        />
        <mail-detail-placeholder
          v-if="filterList && filterList.length > 0 && !previewing && lockedPlane"
          :previewVertical="previewVertical" />
      </div>
      <InappMailMinimize />
    </section>
    <!-------------confirm delete folder------------>
    <Modal
      v-model="confirmShow"
      width="380"
      :styles="{top: '238px'}"
      class="dialog"
      @on-ok="deleteFolder"
    >
      <template slot="header">
        <div class="confirm-delete-header">
          Delete&nbsp;<b class="text-ellipsis">{{ this.folders.selectedFolder ? this.folders.selectedFolder.name : '' }}</b>
        </div>
      </template>
      Do you want to delete this folder?
    </Modal>
    <!-------------confirm delete emails------------>
    <Modal v-model="confirmDeleteMailShow" title="Delete" width="380" :styles="{top: '238px'}" class="dialog"
      @on-ok="deleteEmails">
      Are you sure you want to delete {{checkGroup.length}} email{{checkGroup.length === 1 ? '' : 's'}}?
    </Modal>
    <!-------------confirm archive emails------------>
    <Modal v-model="confirmArchiveMailShow" :title="!isArchivedList ? 'Archive' : 'Unarchive'" width="380" :styles="{top: '238px'}" class="dialog"
      @on-ok="!isArchivedList ? handleArchive() : handleUnarchive()">
      Are you sure you want to {{!isArchivedList ? 'archive' : 'unarchive'}} {{checkGroup.length}} email{{checkGroup.length === 1 ? '' : 's'}}?
    </Modal>
    <MailDetailAttachmentPreviewModal />
    <!-----------------abort send--------------->
    <abort ref="abort" :checkGroup="checkGroup" @reload="reloadEmailList()" v-if="$route_box() && $route_box().toLowerCase()=='sent' && $route_status()==='pending'"></abort>
    <!-------------tag emails------------>
    <tagEmails ref="tagEmails" :checkGroup="checkGroup" @massUpdateTags="massUpdateTags" />
    <!-------------folder details------------>
    <folder-detail ref="folderDetail"></folder-detail>
    <!-------------mail report------------>
    <mailReport ref="mailReport"></mailReport>
    <!---table footer--->
    <chartdesk-footer>
      <span slot="left-content">
        {{ mailList.length }} of {{ totalNumSep }} emails
      </span>
    </chartdesk-footer>
    <!--Right menu on each email-->
    <mail-item-contextmenu
      ref="mailItemContextmenu"
      @onClose="onContextMenuClose"
      @markAsSpam="onMarkAsSpam"
      @markAsHidden="markHidden"
      @markAsUnHide="markUnHide"
      @unSpam="onUnSpamEmails"
      @archive="handleArchiveFromContextMenu"
      @markAsRead="onMarkAsRead"
      @markAsUnread="onMarkAsUnread"
    />
    <mail-poptip-menu
      ref="mailFolderPoptipMenu"
      @onClose="onFolderContextMenuClose"
      :loading="mailFolderLoading"
    >
      <ul class="mail-poptip__folder-menu">
        <li v-for="folder in listFolder" :key="folder.id" :title="folder.name">
          <i class="iconfont iconfont-folder" />
          <span>{{ folder.name }}</span>
        </li>
      </ul>
    </mail-poptip-menu>
  </div>
</template>

<script type="text/ecmascript-6">
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import api from "api";
import util, {insertSpaceBetweenUpper, concatWithoutDuplicates} from "util";
import Cstatus from "@/pages/components/Cstatus";
import abort from "./modal/abort.vue";
import empty from "./empty/empty.vue";
import folderDetail from "./modal/folderDetail.vue";
import mailDetail from "./detail/mailDetail.vue";
import MailDetailPlaceholder from "./detail/MailDetailPlaceholder.vue";
import mailReport from "./report/mailReport.vue";
import tagEmails from "./addTag/tagEmails.vue";
import RightMenuTable from "@/components/RightMenuTable.vue";
import ResizableTableHeader from "@/components/ResizableTableHeader.vue";
import LocalStorageConstant from '@/common/constants/local-storage.constant.js';
import avatar from '@/pages/components/avatar'
import mailItemContextmenu from './contextmenu/mail-item-contextmenu'
import MailPoptipMenu from './contextmenu/MailPoptipMenu'
import { hotkeyHandler, assignmentSocket, tabUtil, popupPosition, mailAddressMixin } from '@/mixins';
import appConstant from "@/common/constants/app.constant";
import assignment from "@/pages/components/assignment";
import mailTabs from "./mail-tabs";
import { RecycleScroller } from 'vue-virtual-scroller'
import debounce from "lodash/debounce";
import EmailFiling from '@/pages/components/emailFiling/EmailFiling.vue';
const EmailListHorizontal = () => import('@/components/EmailListHorizontal.vue');
const EmailListVertical = () => import('@/components/EmailListVertical.vue');
const InappMailMinimize = () => import("@/pages/sendMail/InappMailMinimize.vue");
import InappMailWindow from "@/pages/sendMail/InappMailWindow.vue";
import { parseISO, subSeconds, addDays } from 'date-fns';
import MailDetailAttachmentPreviewModal from './detail/MailDetailAttachmentPreviewModal.vue';
import {emailNotificationCache} from '@/vuex/modules/mails/mail';
import MailFilter from "./MailFilter.vue";
import { union, compact, concat, get }from 'lodash';
import DropdownButton from '@/components/DropdownButton.vue'


Array.prototype.remove = function(val) {
  var index = this.indexOf(val);
  if (index > -1) {
    this.splice(index, 1);
  }
};
const ROW_HEIGHT = 36;
const ROW_HEIGHT_WITH_PREVIEW = 55;
const scrollToTypes = {
  UP: 'up',
  DOWN: 'down'
};
const dateShortcuts = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  THIS_WEEK: 'THIS WEEK',
  LAST_WEEK: 'LAST WEEK'
}

let search_score = {
  up: null,
  down: null
}

let search_email_id = {
  up: null,
  down: null
}

export default {
  name: "mailbox",
  data() {
    return {
      checkGroup: [],
      tableLoading: false,
      loadingMore: false,
      loadingPrevious: false,
      totalNum: 0,
      searchTime: undefined,
      hasMoreItems: false,
      hasPrevItems: false,

      status: null,
      statusWidth: "width:50px",

      previewVertical: true,
      previewing: false,
      previewSize: null,

      selectedRow: null,
      isSelectAll: false,
      checked: false,
      dragTitle: "",

      showFilter: false,
      defaultFilterValue: {
        from: "",
        to: "",
        subject: "",
        tag: "",
        received_at: "",
        received_at_range: "",
        status: "",
        assignees: []
      },
      filter: {},
      received_atOpt: {
        shortcuts: [
          {
            text: "today",
            value() {
              return new Date();
            },
            onClick: picker => { }
          },
          {
            text: "yesterday",
            value() {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              return date;
            },
            onClick: picker => { }
          }
        ]
      },
      //-------Sort order
      sortMethod: null,
      sortField: null,
      readDelay: null,
      //-------folder
      confirmShow: false,
      folder: {},
      //-------delete folder
      deleteLoading: false,
      //-------highlight(search key)
      highlights: [],
      //-------version
      version: "1.0.0",
      tableHeight: "",
      showPreviewDetail: false,
      selectedList: localStorage.getItem(LocalStorageConstant.READ_OR_UNREAD) || "all",
      myWorker: null,
      timeoutClick: [],
      lastSelectIndex: -1,
      confirmDeleteMailShow: false,
      confirmArchiveMailShow: false,
      deleteEmailLoading: false,
      singleClick: true,
      rightMenuLeftPos: "0px",
      rightMenuTopPos: "0px",
      columnObj: this.columnInit(),
      readTimeout: null,
      previewMailBody: false,

      currentTabName: 'all',
      hideFiled: localStorage.getItem(LocalStorageConstant.HIDE_FILED) === "true",
      hideAssigned: localStorage.getItem(LocalStorageConstant.HIDE_ASSIGNED) === "true",
      showHiddenEmail: localStorage.getItem(LocalStorageConstant.SHOW_HIDDEN_EMAIL) === "true",
      assignmentTabs: {
        ALL: {
          label: 'All',
          name: 'all',
          data: {
            assignedTo: appConstant.assignedTo.ALL
          },
          hide: true
        },
        OPEN: {
          label: 'Open',
          name: 'open',
          closable: false,
          type: appConstant.tabTypes.ASSIGNMENT,
          data: {
            assignedTo: appConstant.assignedTo.OPEN
          }
        },
        ASSIGNED_TO_ME: {
          label: 'Assigned to me',
          name: 'me',
          closable: false,
          type: appConstant.tabTypes.ASSIGNMENT,
          data: {
            assignedTo: appConstant.assignedTo.ASSIGNED_TO_ME
          }
        },
        CLOSED: {
          label: 'Closed',
          name: 'closed',
          closable: false,
          type: appConstant.tabTypes.ASSIGNMENT,
          data: {
            assignedTo: appConstant.assignedTo.CLOSED
          }
        }
      },
      assignedToEnum: appConstant.assignedTo,

      scrollTo: undefined,
      goingToBookmark: false,
      bookmarking: false,
      emailParams: undefined,
      maximumLoadedEmails: 20000,

      emailFilingShown: false,
      emailFilingPosition: {},

      dateShortcut: undefined,
      dateIndicatorShown: false,
      prevRowHeight: 0,
      prevScrollerHeight: 0,
      prevScrollTop: 0,
      allowScrollToPrevPosition: true,
      newAssigned: false,
      wsDisconnect: false,
      lastRequestTime: new Date().getTime(),
      assignTabOpenMode: this.$route.query.openMode || localStorage.getItem(LocalStorageConstant.OPEN_MODE) || 'all',
      expandedPreview: false,
      tabsToAlert: [],
      mailFolderLoading: false,
      listFolder: [],

      trigonalLoading: false,
      trigonalCapabilities: []
    }
  },
  created() {
    this.initFilterValues()
    this.isShowAssignmentTabs && (this.currentTabName = this.$route.query.subtab || this.assignmentTabs.OPEN.name)
    this.showFilter = this.setting_mails.show_filter ? this.setting_mails.show_filter === "true" : false;
    this.previewVertical = this.setting_mails.conversation_view ? this.setting_mails.conversation_view === "true" : false;
    util.functionBus.starMail = this.starFolder;
    util.functionBus.unStarMail = this.unStarFolder;
    util.functionBus.readMail = this.readMail;
    util.functionBus.handleColumnSize = this.handleColumnSize;
    util.functionBus.updateEmailListAfterDrag = this.updateEmailListAfterDrag;
    util.functionBus.getCacheKey = this.getCacheKey;
    util.functionBus.removeEmailsFromTable = this.removeEmailsFromTable;
    util.functionBus.clearCheck = this.clearCheck;
    util.functionBus.removeAndGoNext = this.removeAndGoNext;
    this.previewMailBody = this.setting_mails.preview_mail_body == 'true' ? true : false;
    this.initializeCache();
    this.assignTabOpenMode = 
      this.$route.query.openMode ||
      localStorage.getItem(LocalStorageConstant.OPEN_MODE) ||
      this.setting_mails.mail_open_mode ||
      "all"
    window.addEventListener("popupbeforeunload", this.onClosePopupFromMailDetail)
    this.SET_EXPANDED_PREVIEW_MODE(false)
  },
  beforeRouteLeave(to, from ,next) {
    const fromKey = this.getCacheKey(from);
    delete util.routeBasedEmails[fromKey];
    next();
  },
  beforeRouteUpdate(to, from, next) {
    try {
      const toKey = this.getCacheKey(to);
      const fromKey = this.getCacheKey(from);
      const mailList = [...this.mailList];
      if (toKey !== fromKey) {
        this.UPDATE_MAIL_LIST({
          emails: []
        });
      }

      this.initializeCache(toKey, to.query);
      const scroller = this.$refs.mailsTableScroller;

      if (fromKey.includes("usingSearch")) {
        delete util.routeBasedEmails[fromKey];
        return next();
      }

      if (this.p_enabled_assignment && this.$route_folder_id() && this.hideAssigned && this.newAssigned) {
        delete util.routeBasedEmails[fromKey];
        this.newAssigned = false;
        return next();
      }

      const toKeyIndex = util.routeBasedEmailKeys.findIndex(key => key === toKey);
      util.routeBasedEmailKeys.splice(toKeyIndex, 1);
      util.routeBasedEmailKeys.unshift(toKey);

      if (!this.selectedRow || !this.previewing) {
        if (toKey !== fromKey) {
          delete util.routeBasedEmails[fromKey];
          const fromKeyIndex = util.routeBasedEmailKeys.findIndex(key => key === fromKey);
          util.routeBasedEmailKeys.splice(fromKeyIndex, 1);
        }
        return next();
      };

      if (!scroller) return next();

      if (util.routeBasedEmailKeys.length > 15) util.routeBasedEmailKeys.length = 15;

      Object.keys(util.routeBasedEmails).forEach(key => {
        if (!util.routeBasedEmailKeys.includes(key)) {
          toKey !== key && delete util.routeBasedEmails[key]
        }
      })

      if (util.routeBasedEmails[fromKey]) {
        util.routeBasedEmails[fromKey].emails = [];
        util.routeBasedEmails[fromKey].query = {...this.$route.query};
        util.routeBasedEmails[fromKey].previewing = this.previewing;
        util.routeBasedEmails[fromKey].selectedRow = this.selectedRow;
        if (this.selectedRow) {
          util.routeBasedEmails[fromKey].selectedRowIndex = mailList.findIndex(e => e.id === this.selectedRow.id);
        }
        util.routeBasedEmails[fromKey].scrollTop = scroller.$el.scrollTop;
        util.routeBasedEmails[fromKey].total = this.totalNum;
        util.routeBasedEmails[fromKey].extraOptions = {
          sortField: this.sortField,
          sortMethod: this.sortMethod,
          totalNum: this.totalNum,
          selectedList: this.selectedList,
          hasPrevItems: this.hasPrevItems,
          dateShortcut: this.dateShortcut
        };
      }

      this.allowScrollToPrevPosition = true;
      this.totalNum = 0;

      next();
    } catch (error) {
      console.log(error);
      next();
    }
  },
  mounted() {
    if (this.$route.query.id && !this.$route.query.from) {
      //handling cases going from aisde nm
      this.$router.replace({
        path: "/mails",
        query: { detail_Id: this.$route.query.id }
      });
      this.$refs.mailDetail && this.$refs.mailDetail.show();
    }
    this.autoRead = this.setting_mails.auto_read_email == "true";
    if (this.autoRead) {
      this.readDelay = this.setting_mails.auto_read_email_time;
    }
    this.handleConfigurationData()
    this.handleSocket();
    this.reloadIfNewVersion();
    !this.activeTab && this.reloadEmailList();
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleColumnSize)
      this.scrollToLoadMore();
    })
    window.addEventListener("beforeunload", e => {
      if (!this.inappMailState.some(a => a.type === appConstant.inappType.COMPOSE)) return;
      e.preventDefault();
      e.returnValue = false;
      return "Are you sure to leave this page?"
    });
    window.addEventListener("keydown", this.selectUpDown);
    setTimeout(() => {
      this.getBookmark();
    }, 1200);
    this.loadTrigonalCapabilities()
  },
  beforeDestroy() {
    delete util.functionBus.starMail;
    delete util.functionBus.unStarMail;
    delete util.functionBus.readMail;
    delete util.functionBus.handleColumnSize;
    delete util.functionBus.updateEmailListAfterDrag;
    delete util.functionBus.getCacheKey;
    delete util.functionBus.removeEmailsFromTable;
    delete util.functionBus.clearCheck;
    window.onresize = null;
    window.removeEventListener("popupbeforeunload", this.onClosePopupFromMailDetail)
    window.removeEventListener('resize', this.handleColumnSize)
    window.removeEventListener("keydown", this.selectUpDown);
  },
  mixins: [
    hotkeyHandler,
    assignmentSocket,
    popupPosition,
    tabUtil,
    mailAddressMixin
  ],
  provide() {
    return {
      toggleExpandedPreview: this.toggleExpandedPreview
    }
  },
  methods: {
    ...mapActions([
      "setDragedMail",
      "readEmail",
      "starMail",
      "removeMails",
      "delete_folder",
      "star_folder",
      "archive",
      "unarchive",
      "composeMail",
      "updatePreviewMailSetting",
      "addBookmark",
      "removeBookmark",
      "getBookmark",
      "markAsSpam",
      "unSpamEmails",
      "getEmailBody"
    ]),
    ...mapMutations([
      "SET_MAILS_SETTINGS",
      "UPDATE_MAIL_LIST",
      'setStarStatus',
      'setBookmark',
      'REMOVE_OPEN_TAB',
      "UPDATE_MAIL_LIST_ITEMS",
      "DELETE_MAIL_LIST_ITEMS",
      "SET_EXPANDED_PREVIEW_MODE",
      "SET_LANDING_TO_LAST_SEEN_EMAIL"
      ]),
    toggleExpandedPreview(status) {
      if (status === undefined) {
        this.expandedPreview = !this.expandedPreview
        return;
      }

      this.expandedPreview = status;
    },
    handleSocketMail(item) {
      let socket = this.$ActionCable.subscriptions.subscriptions[0];
      socket.perform('confirmed', { email_notification_id: item.email_notification_id})
      if (this.$route.name === "mail") {
        console.log("new email", item);

        util.sendMessageToSw(
          {
            message: "showNotification",
            params: {
              type: "incomingEmail",
              data: {
                subject: item.subject,
                username: item.username,
                id: item.id
              }
            }
          }
        );

        util.sendMessageToSw({
          message: "removeEmailDetail",
          email_ids: [item.id]
        });

        if (this.selectedRow && this.selectedRow.id === item.id && this.previewing) {
          this.$refs.mailDetail && this.$refs.mailDetail.updateDetail(item);
        }
        if (!item.history) {
          let mailArray = [item];
          if (item.message && item.message.length > 0)
            mailArray = [...item.message];

          this.handleLineTabAlert(mailArray);
          this.checkLiveFeeds(item);

          Object.keys(util.routeBasedEmails).forEach(key => {
            const routeQuery = util.routeBasedEmails[key].query
            const liveFeedId = routeQuery.live_feed_id ? `${appConstant.tabPrefixes.LIVE_FEED}${routeQuery.live_feed_id}` : '';
            const tabId = routeQuery.tab || liveFeedId;
            if (this.shouldNotAppendEmailToLiveFeed(tabId)) return;

            const box = this.$route_box(routeQuery);
            const status = this.$route_status(routeQuery);
            const archived = this.$route_archived(routeQuery);
            const me = routeQuery.me;
            const star = routeQuery.star;
            const search = routeQuery.search;
            const search_key = routeQuery.search_key;
            const line_id = parseInt(this.$route_line_id(routeQuery));
            const folder_id = parseInt(this.$route_folder_id(routeQuery));
            const is_star = routeQuery.is_star;

            const mailParams = { box, status, archived, me, search, search_key, line_id, folder_id, is_star };
            this.p_enabled_assignment && folder_id && this.hideAssigned && (this.newAssigned = true);
            if(this.p_enabled_assignment && this.currentTabName !== this.assignmentTabs.ALL.name) {
              this.handleAssignedEmailSocket(mailArray, mailParams, key);
              return;
            }
            this.handleEmailSocket(mailArray, mailParams, key);
          })

          if (this.usingSearch) return;

          this.updateCurrentEmailList();
        }
      }
    },
    checkLiveFeeds(email) {
      this.tabsToAlert = [];

      (this.liveFeeds || []).forEach(feed => {
        const tabName = `${appConstant.tabPrefixes.LIVE_FEED}${feed.id}`;

        if (
          feed.utils &&
          feed.utils.isMailboxMatched(email.line_ids) &&
          feed.utils.isTypeMatched(email.box) &&
          feed.utils.isSubjectIncludeMatched(email.subject) &&
          feed.utils.isSubjectExcludeMatched(email.subject) &&
          feed.utils.isDateMatched(email.received_at) &&
          feed.utils.isFromContainMatched(email.from) &&
          feed.utils.isFromExcludeMatched(email.from)
        ) {
          this.$refs.mailTabs && this.$refs.mailTabs.handleIncomeAlert(tabName);
          this.tabsToAlert.push(tabName)
        }
      })
    },
    handleSocketMarkAsRead(email_ids) {
      this.UPDATE_MAIL_LIST_ITEMS({
        ids: [...email_ids],
        changedProps: (_) => ({ read: true })
      })
    },
    handleSocketTag(data) {
      if (data.type === "Tag") {
        data.message.forEach(message => {
          if (message.tagable_type === "Email") {
            const deleted = !!message.deleted_at;
            this.updateTags(message.name, message.tagable_id, deleted);
          }
        })
      }
    },
    handleSocketUnreadCount(data) {
      this.$store.commit("SET_LINECOUNT", data);
    },
    handleSocketMailClosed(data) {
      this.handleAssignmentSocket({...data, action: 'closed'});
      if(this.bookmark && this.bookmark.email_id == data.id)
        this.setBookmark({ ...this.bookmark, closed: data.closed })
    },
    async handleSocketEmailFolder(data) {
      if(!data) return;

      const { email_ids, folder_id, emails } = data;
      if(!email_ids || email_ids.length == 0) return;

      this.UPDATE_MAIL_LIST_ITEMS({
        ids: [...email_ids],
        changedProps: (_) => ({ filed: true })
      })

      const hasIncomingEmail = (emails || []).some(email => (email.box || '').toLowerCase() !== 'sent')
      hasIncomingEmail && this.$refs.mailTabs && this.$refs.mailTabs.handleIncomeAlert(`${appConstant.tabPrefixes.FOLDER}${folder_id}`);

      email_ids.map(id => {
        if (emailNotificationCache.has(id)) {
          const email = emailNotificationCache.get(id);
          email.folder_ids = [...new Set([...(email.folder_ids || []), folder_id])]
          email.filed = true;

          const keys = Object.keys(util.routeBasedEmails).filter(key => key.includes(`folder_id=${folder_id}`));
            keys.forEach(key => {
              const eMap = util.routeBasedEmails[key].emailMap;
              if (!eMap || eMap.size === 0) return;

              !eMap.has(email.id) && eMap.set(email.id, email);
            })
        }
      })

      folder_id == this.$route_folder_id() && this.updateCurrentEmailList();
    },
    handleSocketTrigonalFiling({email_id}) {
      if(!email_id) 
        return

      this.UPDATE_MAIL_LIST_ITEMS({
        ids: [email_id],
        changedProps: (_) => ({ trig_file_details: true })
      })
    },
    async handleSocketTrigonalPreviewEmail({email_id, error_message}) {
      if(error_message) {
        const params = [
          "height=300",
          "width=700",
          "location=no",
          "top=" + (screen.height - 300) / 2,
          "left=" + (screen.width - 700) / 2,
          "fullscreen=no"
        ].join(",");
        let popup = window.open('', '_blank', params)
        popup.document.write(error_message)
        return
      }

      if(!email_id) 
        return

      const res = await api.get_email_details({ email_id });

      if(!res || !res.email) 
        return

      this.openNewWindow(res.email)
    },
    handleSocket() {
      const self = this;
      let socket = this.$ActionCable.subscriptions.subscriptions[0];
      this.initializeCache();

      socket.connected.mail = function() {
        if(!self.online || !self.wsDisconnect)
          return
        self.loadEmailList({ run_background: true })
        self.$refs.mailTabs && self.$refs.mailTabs.handleWarning()
        self.wsDisconnect = false
      }

      socket.disconnected.mail = function() {
        self.wsDisconnect = true
      }
      socket.received.mail = this.handleSocketMail;
      socket.received.markAsRead = this.handleSocketMarkAsRead
      socket.received.tag = this.handleSocketTag;
      socket.received.unreadCount = this.handleSocketUnreadCount;
      socket.received.assignment = this.handleAssignmentSocket;
      socket.received.mailClosed = this.handleSocketMailClosed;
      socket.received.emailFolder = this.handleSocketEmailFolder
      socket.received.trigonalFilingSuccess = this.handleSocketTrigonalFiling
      socket.received.trigonalPreviewEmail = this.handleSocketTrigonalPreviewEmail
    },
    handleEmailSocket(emails, mailParams, key) {
      const toAppendEmails = [];
      emails.forEach(email => {
        const emailMap = util.routeBasedEmails[key].emailMap;
        if(this.willDelete(email, mailParams, key)) {
          this.previewing
            && this.selectedRow
            && this.selectedRow.id === email.id
            && this.closePreviewDetail();
          emailMap.delete(email.id)
          return;
        }
        if (this.willAppend(email, mailParams, key)) {
          toAppendEmails.push(email)
        }
      });
      toAppendEmails.length > 0 && this.appendTableData(toAppendEmails, key);
    },
    handleAssignedEmailSocket(emails, mailParams, key) {
      const emailMap = util.routeBasedEmails[key].emailMap;

      emails.forEach(email => {
        if(this.willDelete(email, mailParams, key)) {
          this.previewing
            && this.selectedRow
            && this.selectedRow.id === email.id
            && this.closePreviewDetail();
          emailMap.delete(email.id);
          return;
        }
        const has = emailMap.has(email.id);
        const existingEmail = has ? emailMap.get(email.id) : {};
        const willAppend = this.willAppend(email, mailParams, key) && this.willAppendAssignedEmail(email, key);
        const assignedToMe = !email.closed
              && email.assignment_ids
              && email.assignment_ids.findIndex(a => a.assignable_id == this.userInfo.user.id) !== -1;
        const assignedToOthers = !email.closed
              && email.assignment_ids
              && email.assignment_ids.length > 0
              && !email.assignment_ids.some(a => a.assignable_id == this.userInfo.user.id);

        switch (true) {
          case key.includes("tabStr=all"):
            willAppend && emailMap.set(email.id, {...existingEmail, ...email})
            break;
          case key.includes("tabStr=me"):
            willAppend && assignedToMe && emailMap.set(email.id, {...existingEmail, ...email});
            has && !assignedToMe && emailMap.delete(email.id);
            break;
          case key.includes("tabStr=closed"):
            willAppend && email.closed && emailMap.set(email.id, {...existingEmail, ...email});
            !email.closed && has && emailMap.delete(email.id);
            break;
          case key.includes("tabStr=focused"):
            willAppend && !assignedToOthers && emailMap.set(email.id, {...existingEmail, ...email});
            has && assignedToOthers && emailMap.delete(email.id);;
            has && email.closed && emailMap.delete(email.id);
            break;
          default:
            willAppend && !email.closed && emailMap.set(email.id, {...existingEmail, ...email});
            email.closed && has && emailMap.delete(email.id);
            break;
        }
      });

      if (key === this.getCacheKey()) {
        this.updateCurrentEmailList();
      }
    },
    handleLineTabAlert(emails) {
      if(!this.$refs.mailTabs) return;

      emails.forEach(email => {
        email.line_ids && email.line_ids.forEach(line_id => {
          let tabParentId = email.box && email.box.toLowerCase() || 'mails';
          if(email.box.toLowerCase() == 'sent' && ['pending', 'delay'].includes(email.status.toLowerCase()))
            tabParentId = 'outbox';
          if(email.status.toLowerCase() == 'sent')
            tabParentId = 'sent';
          if(email.archived)
            tabParentId = 'archived';

          const isIncomingEmail = (email.box || '').toLowerCase() !== 'sent';
          isIncomingEmail && this.$refs.mailTabs.handleIncomeAlert(`${appConstant.tabPrefixes.LINE}${line_id}_${tabParentId}`);

          const belongToMailActivity = email.box.toLowerCase() != 'spam' && email.box.toLowerCase() != 'draft';
          if(belongToMailActivity && isIncomingEmail)
            this.$refs.mailTabs.handleIncomeAlert(`${appConstant.tabPrefixes.LINE}${line_id}_mails`);
        })
      })
    },
    willAppendAssignedEmail(email, key) {
      const res = util.routeBasedEmails[key];
      const self = key === this.getCacheKey() ? this : res.extraOptions;
      if(!res || !res.emailMap || !email)
        return false;
      if(res.emailMap.size == 0)
        return true;
      const lastEmail = [...res.emailMap.values()].pop();
      const dateSubtract = new Date(email.received_at) - new Date(lastEmail.received_at);
      const validDate = self.sortMethod == 'asc' ? dateSubtract <= 0 : dateSubtract >= 0;
      return validDate || res.emailMap.size >= self.totalNum;
    },
    columnInit() {
      let columns = JSON.parse(localStorage.getItem("columns")) || {
        "From": true,
        "To": true,
        "Date/Time": true,
        "Assignment": true,
        "Tag": true
      }

      !columns['Mailbox'] && (columns["Mailbox"] = false)

      delete columns['Star']
      delete columns['Status']
      delete columns['Filed']

      return columns
    },
    customizeTableColumn(e) {
      this.$refs.rightMenuTable.showMenu = true;
      this.$nextTick(() => {
        this.rightMenuLeftPos = e.clientX+"px";
        this.rightMenuTopPos = e.clientY+"px";
      })
    },
    handleChangeColumn(columnName) {
      if (this.columnObj[columnName]) {
        this.$set(this.columnObj, columnName, false)
      } else {
        this.$set(this.columnObj, columnName, true);
      }
      this.handleColumnSize();
      localStorage.setItem("columns", JSON.stringify(this.columnObj));
    },
    handleColumnSize: debounce(function() {
      const table = this.$refs.mailTable;
      const tableWidth = table && table.offsetWidth || 0;
      const parentWidth = table && table.parentElement && table.parentElement.offsetWidth || 0;
      const gap = tableWidth - parentWidth;

      let widestEl, width = 0;
      if (gap < 0) {
        width = parseInt(document.documentElement.style.getPropertyValue('--subject-td-width'));
        widestEl = "--subject-td-width";
      } else this.$refs.resizableHeader && Object.entries(this.$refs.resizableHeader.columns).forEach(([id, value]) => {
        const itemWidth = parseInt(document.documentElement.style.getPropertyValue(value.property));
        if (itemWidth >= width) {
          width = itemWidth;
          widestEl = value.property;
        }
      });

      width -= gap;
      if (width < 50) return;
      document.documentElement.style.setProperty(widestEl, `${width}px`)
      this.getAssignmentColumnWidth();
    }, 300),
    willAppend(email, params, key ) {
      const res = util.routeBasedEmails[key];
      const self = key === this.getCacheKey() ? this : {...res.extraOptions};
      const { box, status, archived, me, search, search_key, line_id, folder_id, is_star } = params;

      if (self.sortMethod === "asc" && res.emailMap.size < self.totalNum) return false;
      if (archived || me || search || search_key || this.hasFilterChange || is_star) return false;
      if (self.selectedList === 'read' && !email.read) return false;
      if (self.selectedList === 'unread' && email.read) return false;
      if (line_id && (!email.line_ids || !email.line_ids.includes(line_id))) return false;
      if (folder_id && (!email.folder_ids || !email.folder_ids.includes(folder_id))) return false;
      if (box && box.toLowerCase() === 'sent'
        && status && status.toLowerCase() === "pending"
        && (email.status.toLowerCase() === 'pending' || email.status.toLowerCase() === 'delay')) return true;
      if (box && box.toLowerCase() !== email.box.toLowerCase()) return false;
      if (!box && email.box.toLowerCase() == 'spam') return false;
      if (!box && email.box.toLowerCase() === 'draft') return false;
      if (status && status.toLowerCase() !== email.status.toLowerCase()) return false;
      if(self.hasPrevItems) return false;
      if(self.dateShortcut && self.dateShortcut != dateShortcuts.TODAY) return false;
      return true;
    },
    willDelete(email, params, key) {
      const { box, status } = params;
      const res = util.routeBasedEmails[key];
      const has = res.emailMap.has(email.id);
      if(!has)
        return false;

      if (!box && email.box.toLowerCase() === 'draft')
        return true;

      if (box && box.toLowerCase() === 'sent'
        && status && status.toLowerCase() === "pending"
        && email.status.toLowerCase() === 'sent')
        return true;

      if (box && box.toLowerCase() === 'draft'
        && (email.status.toLowerCase() === "pending"
          || email.status.toLowerCase() === 'delay'))
        return true;

      return false;
    },
    appendTableData(items, key) {
      const emailMap = util.routeBasedEmails[key].emailMap;

      items.forEach(item => {
        item.filed = item.folder_ids && item.folder_ids.length > 0;
        if (emailMap.has(item.id)) {
          const clonedItem = {...item};
          delete clonedItem.filed;
          delete clonedItem.read;
          const currentItem = emailMap.get(clonedItem.id);
          Object.assign(currentItem, clonedItem) // Update UI automatically
        } else {
          emailMap.set(item.id, item)
        }
      })
    },
    clickCurrentRow(row, index) {
      this.closeMailContextMenu();
      this.closeMailFolderPoptip();
      this.lastSelectIndex = index;
      this.selectedRow = this.mailList[index];

      if (!this.previewing) {
        this.prevRowHeight = this.rowHeight;
        this.prevScrollerHeight = this.$refs.mailsTableScroller.$el.offsetHeight;
        this.prevScrollTop = this.$refs.mailsTableScroller.$el.scrollTop;
      }

      this.timeoutClick.push(
        setTimeout(() => {
          this.previewEmail("single");
        }, 400)
      );
    },
    dblclickCurrentRow(row, index) {
      this.timeoutClick.forEach(id => {
        clearTimeout(id);
      })
      this.timeoutClick = [];
      this.previewEmail("double")
    },
    previewEmail(clickType) {
      if (clickType==="single") {
        this.singleClick = true;
        this.previewing = true;

        this.allowScrollToPrevPosition && this.$nextTick(() => {
          this.scrollToPrevPosition();
          this.allowScrollToPrevPosition = false;
        })

        return;
      }
      if (clickType==="double") {
        this.readMail(() => {
          !this.previewing && (this.selectedRow = null);
        });
        if (this.setting_mails.double_click_mail == "same_window") {
          this.previewing = true;
          this.singleClick = false;
          this.toggleExpandedPreview();
        } else {
          this.openNewWindow(this.selectedRow);
        }
        return;
      }
    },
    readMail(callback) {
      if (!this.selectedRow || this.selectedRow.read)
        return;
      this.readTimeout && clearTimeout(this.readTimeout)
      const para = {
        rowId: this.selectedRow.id,
        lineId: this.selectedRow.line_ids,
        receivedAt: this.selectedRow.received_at,
        box: this.selectedRow.box,
        archived: this.selectedRow.archived
      };
      if (!this.readDelay || this.readDelay == 0) {
        this.handleRead(para);
        callback && callback();
        return;
      }
      this.readTimeout = setTimeout(() => {
        this.handleRead(para);
        callback && callback();
      }, +this.readDelay*1000);
    },
    openNewWindow(data) {
      let {id, subject} = data;
      localStorage.setItem(`mail_window_data_${id}`, JSON.stringify(data))

      subject = util.removeSearchHtml(subject);
      subject = util.filterTitle(subject);

      const folderId = +this.$route_folder_id();
      const folderIdQuery = folderId ? `folder_id=${folderId}` : ``;
      const folderName = this.$route_folder_name();
      const folderNameQuery = folderName ? `&folder_name=${folderName}` : ``;
      const folderTypeQuery = this.$route.query.type ? `&type=${this.$route.query.type}` : ``;
      let subUrl = `mail/${id}?${folderIdQuery}${folderNameQuery}${folderTypeQuery}`;
      if (this.$route.query.via) {
        subUrl += folderId ? `&via=notification` : `via=notification`;
      }

      let popup = util.openWindow(subUrl);
      popup.onload = () => {
        popup.document.title = util.removeSearchHtml(subject);
      }
    },
    openReport(id, status) {
      this.$refs.mailReport.show(id, status);
    },
    toggleSort(filed) {
      util.resetCachedEmails(this.getCacheKey());
      if (!this.sortField) this.sortField = "received_at";
      const oldType = this.sortField;
      if (this.sortMethod === "asc") {
        this.sortMethod = "desc";
      } else {
        this.sortMethod = "asc";
      }
      if (oldType !== filed) {
        this.sortMethod = "desc";
      }
      this.sortField = filed;
      this.reloadEmailList();
    },
    restoreListStatus(res, swEvent = false) {
      const scroller = this.$refs.mailsTableScroller;

      let selectedRow = res.selectedRow;
      let selectedRowIndex = res.selectedRowIndex !== undefined ? res.selectedRowIndex : -1;
      let newSelectedRowIndex = -1;

      if (selectedRow) {
        newSelectedRowIndex = res.emails.findIndex(e => e.id === res.selectedRow.id);
        selectedRow = res.emails[newSelectedRowIndex];
      }

      if (!swEvent) {
        this.selectedRow = selectedRow;
        this.previewing = !!this.selectedRow;
      }

      this.$nextTick(()=> {
        const newGap = (newSelectedRowIndex - selectedRowIndex) * this.rowHeight;
        scroller && scroller.$el && (scroller.$el.scrollTop = res.scrollTop + newGap);
      })
    },
    restoreData(res, swEvent) {
      if (!this.usingSearch && !this.loadingMore && !this.loadingPrevious) {
        const data = Object.assign(util.routeBasedEmails[this.getCacheKey()], res, { emails: this.mailList });
        this.restoreListStatus(data, swEvent);
      }

      this.tableLoading = this.loadingMore = this.loadingPrevious = this.goingToBookmark = false;
    },
    fetchEmailDataInBackground(params) {
      if(this.sortMethod && this.sortMethod == 'asc')
        return
      api.get_email(params).then(async (res) => {
        if(!res || res.request_time < this.lastRequestTime)
          return
        let mailList = this.mailList
        const existingEmails = res.emails.filter(i => mailList.findIndex(mi => mi.id == i.id) > -1) || []
        const newEmails = res.emails.filter(i => mailList.findIndex(mi => mi.id == i.id) == -1) || []
        existingEmails.forEach(item => {
          let email = mailList.find(m => m.id == item.id)
          email = Object.assign(email, item)
        })
        mailList.push(...newEmails)
        mailList = mailList.sort((x, y) => {
          const num = this.sortMethod === "asc" ? -1 : 1;
          return num * (new Date(y.received_at) - new Date(x.received_at));
        })
        await this.UPDATE_MAIL_LIST({
          emails: mailList,
          key: this.getCacheKey()
        });
        if(this.isPreloadEmailBody) {
          this.getEmailBody({ ...res.email_body_token })
        }
      })
    },
    getEmailData(params) {
      this.initializeCache();
      const cachedRes = util.routeBasedEmails[this.getCacheKey()];

      if (cachedRes.emailMap && cachedRes.emailMap.size > 0
        && !params.scroll
        && !this.usingSearch
        && !params.no_cache
      ) {
        cachedRes.emails = [...cachedRes.emailMap.values()];
        this.allowScrollToPrevPosition = false;
        this.sortMethod = cachedRes.extraOptions.sortMethod;
        this.sortField = cachedRes.extraOptions.sortField;
        this.updateUI(cachedRes, params);
        this.restoreData(cachedRes);
        this.emailParams = params;
        this.loadEmailList({ run_background: true });
        return;
      };

      if (!this.loadingMore && !this.loadingPrevious) {
        this.tableLoading = true;
        this.UPDATE_MAIL_LIST({
          emails: []
        });
        this.$refs.mailsTableScroller.$el.scrollTop = 0;
        this.previewing = false;
        this.selectedRow = null;
        this.totalNum = 0;
      }

      this.emailParams = params;

      api.get_email(params)
        .then(res => {
          if(res.request_time < this.lastRequestTime) return
          this.updateUI(res, params, true);
          !params.scroll && this.restoreData(res);
        })
        .catch(error => {
          console.log(error);
          if (error.message && error.message.cancel)
            return;
          this.tableLoading = this.loadingMore = this.loadingPrevious = this.goingToBookmark = false;
        });
    },
    async updateUI(res, params, withBodies = false) {
      if(!res) return;
      if(this.goingToBookmark && res.emails.length == 0) {
        this.$Message.info("Your bookmark location does not exist anymore. Please try to bookmark another email.");
        this.reloadEmailList();
        return;
      }

      this.scrollTo = params.scroll;
      this.highlights = res.highlights ? res.highlights : this.highlights;
      this.totalNum = res && res.total && (!params.scroll || params.bookmark) ? res.total : this.totalNum;
      this.searchTime = res.search_time;
      this.hasMoreItems = params.scroll !== scrollToTypes.UP ? res.has_more_items : this.hasMoreItems;
      this.hasPrevItems = params.scroll === scrollToTypes.UP ? res.has_more_items : this.hasPrevItems;
      this.folder = res.folder;

      params.bookmark && (this.UPDATE_MAIL_LIST({
        emails: []
      }));

      let mailList = [];
      switch(params.scroll) {
        case undefined:
        case null:
        case "":
          mailList = res.emails;
          this.saveSearchEmailDown(res.emails);
          break;
        case scrollToTypes.DOWN:
          mailList = this.mailList;
          mailList.push(...res.emails);
          this.saveSearchEmailDown(res.emails);
          break;
        case scrollToTypes.UP:
          mailList = this.mailList;
          mailList.unshift(...res.emails);
          this.scrollToRow(res.emails.length);
          break;
      }
      !this.usingSearch && (mailList = mailList.sort((x, y) => this.sortCallback(x, y)));

      const newMap = new Map()
      for(const mail of mailList) {
        newMap.set(mail.id, mail)
      }
      mailList = [...newMap.values()]

      await this.UPDATE_MAIL_LIST({
        emails: mailList,
        key: this.getCacheKey()
      });
      if(this.isPreloadEmailBody && withBodies) {
        this.getEmailBody({ ...res.email_body_token })
      }
      this.isSelectAll && this.selectAllChanged(this.isSelectAll);

      if (params.bookmark && this.currentBookmark && newMap.has(this.currentBookmark.email_id)) {
        this.previewing = true;
        this.selectedRow = newMap.get(this.currentBookmark.email_id);
        this.allowScrollToPrevPosition = false;

        setTimeout(() => {
          this.loadingMore = true;
          this.loadEmailList({scrollTo: scrollToTypes.DOWN, search_email_id: this.currentBookmark.email_id}, false);
        }, 500)
      }

      this.tableLoading = this.loadingMore = this.loadingPrevious = this.goingToBookmark = false;
    },
    saveSearchEmailUp(emails) {
      const firstEmail = emails[0]
      if (!firstEmail) return;
      search_email_id.up = firstEmail.id;
      search_score.up = firstEmail.score
    },
    saveSearchEmailDown(emails) {
      const lastEmail = emails.slice(-1)[0]
      if (!lastEmail) return;
      search_email_id.down = lastEmail.id;
      search_score.down = lastEmail.score
    },
    onSelectAll() {
      this.isSelectAll = !this.isSelectAll;
    },
    selectAllChanged(checkAll) {
      if (checkAll) {
        let checked_ids = new Set(this.checkGroup);
        this.mailList.map(item => checked_ids.add(item.id));
        this.checkGroup = Array.from(checked_ids);
      }
      !checkAll && this.mailList.map(item => this.checkGroup.remove(item.id));
      if (this.mailList.length > 0)
        this.dragTitle = this.mailList[0].subject;
    },
    clearCheck() {
      this.isSelectAll = false;
      this.checkGroup = [];
      this.lastSelectIndex = -1;
    },
    checkGroupChange(index) {
      this.dragTitle = this.mailList[index].subject;
    },
    onDragStart(index, e) {
      e.dataTransfer.setData("Text", "");
      localStorage.setItem("dragType", "sendmail");

      const subjectCell = e.target.querySelector(".table-subject");
      if (this.checkGroup.length === 0 || this.checkGroup.length === 1) {
        subjectCell && (subjectCell.style.padding = '8px')
        subjectCell && e.dataTransfer.setDragImage(subjectCell, 10, 10);
        localStorage.setItem("attachment", this.mailList[index].id);
        this.setDragedMail([this.mailList[index].id]);
      } else {
        let dragEl = this.$refs.dragImg;
        dragEl.style.display = 'block';
        dragEl.style.top = e.clientY;
        dragEl.style.left = e.clientX;
        e.dataTransfer.setDragImage(this.$refs.dragImg, 10, 10);
        this.setDragedMail(this.checkGroup);
        localStorage.setItem("attachment", JSON.stringify(this.checkGroup));
      }
    },
    onDragEnd(e) {
      e.preventDefault();
      this.setDragedMail([]);
      e.dataTransfer.clearData();
      localStorage.removeItem("dragType");
      localStorage.removeItem("attachment");
      return false;
    },
    enterFilter() {
      if (this.$route.query.edit && this.$route.query.edit === "false")
        this.$route.query.edit = "true";
      this.reloadEmailList();
    },
    toggleFilter() {
      if (this.showFilter == true) {
        this.initFilterValues()
        this.dateShortcut = undefined;
        this.dateIndicatorShown = false;
        this.reloadEmailList();
      }
      this.showFilter = !this.showFilter;
    },
    readChange(value) {
      util.resetCachedEmails(this.getCacheKey());
      switch (value) {
        case "read":
          this.reloadEmailList({flag: "read"});
          break;
        case "unread":
          this.reloadEmailList({flag: "unread"});
          break;
        case "all":
          this.reloadEmailList();
          break;
      }
      localStorage.setItem(LocalStorageConstant.READ_OR_UNREAD, value);
    },
    loadEmailList(options, loading = true) {      
      !options && (options = {});
      //----------------------------------filter
      let filter = Object.assign({}, this.filter);
      delete filter.assignees
      for (let item in filter) {
        if (filter[item] == "")
          delete filter[item];
        if (item == "status" && filter[item])
          filter[item] = filter[item].split(",");
      }
      //----------------------------------search
      let advance = {}
      if (this.$route.query.search === "advance") {
        let rules = JSON.parse(this.mailAdvance.rules)
        rules = rules.filter(r => !!r.content && r.field !== 'keyword')
        advance = {
          q: {...this.mailAdvance, rules: JSON.stringify(rules)}
        };
        if (this.$route.query.edit === "false") {
          filter = {};
        }
      }
      if (this.$route.query.search_key) {
        var keyWord = {
          key: this.$route.query.search_key
            .replaceAll(/“/gi, '"')
            .replaceAll(/”/gi, '"')
            .replaceAll(/‘/gi, "'")
            .replaceAll(/’/gi, "'")
        };
      }
      //----------------------------------status
      if (this.$route_status() === "pending") {
        this.status = ["pending", "failed", "delay", "schedule", "progress"];
      } else if (this.$route_status() === "sent") {
        this.status = ["sent"];
      } else {
        this.status = [];
      }
      //-----------------------------------assignment
      let a = this.currentAssignTo
      if(this.p_enabled_assignment && this.filter.assignees && this.filter.assignees.length > 0) {
        const assignee = this.filter.assignees[0]
        a = (assignee == 'any' && a == appConstant.assignedTo.ALL) ? appConstant.assignedTo.ASSIGNED
          : (assignee == 'none' && a == appConstant.assignedTo.ALL) ? appConstant.assignedTo.UNASSIGNED
            : a == appConstant.assignedTo.ALL ? this.filter.assignees.join(',')
              : (a === 'focused' ? 'open' : a) + ',' + this.filter.assignees.join(',')
      }
      if(this.isSpamList) a = appConstant.assignedTo.ALL

      let s_score;
      let s_email_id;
      if(this.mailList && this.mailList.length > 0 && options.scrollTo) {
        this.saveSearchEmailUp(this.mailList);

        s_email_id = options.scrollTo == scrollToTypes.UP ? search_email_id.up : search_email_id.down
        s_score = options.scrollTo == scrollToTypes.UP ? search_score.up : search_score.down
      }
      //----------------------------------load mails
      const search_all_lines = this.$route.query.search_all_lines
      const flag = this.selectedList == "all" ? null : this.selectedList;
      let params = {
        per_page: 100,
        search_email_id: options.search_email_id || s_email_id,
        search_time: this.searchTime,
        score: options.search_email_id || !s_score ? undefined : s_score,
        scroll: options.scrollTo,
        no_cache: options.no_cache,
        bookmark: options.bookmark || false,
        folder_id: this.$route_folder_id(),
        box: this.$route_box(),
        line_id: search_all_lines != 'true' ? options.line_id || this.$route_line_id() : undefined,
        archived: this.$route_archived(),
        order: this.sortField
          ? `${this.sortField} ${this.sortMethod}`
          : undefined,
        status: this.status,
        is_star: this.$route.query.is_star ? this.$route.query.is_star : undefined,
        me: this.$route.query.me,
        search_all_lines: search_all_lines,
        flag: options.flag || flag || undefined,
        a: a,
        with_hidden: !!this.showHiddenEmail,
        filed: !!this.hideFiled
      }

      const liveFeedId = this.$route.query.tab && this.$route.query.tab.startsWith("lf_") 
        ? this.$route.query.tab.split("lf_")[1]
        : this.$route.query.live_feed_id
      if (liveFeedId) {
        const feed = this.liveFeeds.find(feed => feed.id.toString() == liveFeedId);
        feed && feed.utils.updateQueryParams(params, !!this.$route.query.bm);
      }

      if (filter.received_at) {
        filter.received_at = parseISO(filter.received_at);
      }
      if (get(filter, 'received_at_range', []).length > 0) {
        filter.received_at_range = [
          parseISO(filter.received_at_range[0]),
          subSeconds(addDays(parseISO(filter.received_at_range[1]), 1), 1)
        ]
      }

      Object.assign(params, {...filter, ...advance, ...keyWord});

      this.lastRequestTime = new Date().getTime();
      const fetchEmailData = options.run_background
        ? this.fetchEmailDataInBackground
        : this.getEmailData
      if(params.folder_id) {
        let { box, line_id, archived, status, is_star, me, filed, ...folderParams } = params;

        if (this.$route_folder_type() !== 'normal' ) {
          folderParams.filed = filed
        }

        fetchEmailData(folderParams);
        return;
      }
      if(params.line_id) {
        let { folder_id, is_star, me, ...lineParams } = params;
        fetchEmailData(lineParams);
        return;
      }
      return fetchEmailData(params);
    },
    reloadEmailList(options, loading=true) {
      this.checkGroup = [];
      this.isSelectAll = false;
      this.hasMoreItems = this.hasPrevItems = false;
      this.score = 0;
      this.searchTime = this.refreshSearchTime();
      this.loadEmailList(options, loading);
      this.handleColumnSize();
    },
    reloadEmailListWithBookmark(emailId) {
      this.reloadEmailList(
        {
          bookmark: true,
          search_email_id: emailId,
          scrollTo: scrollToTypes.UP,
        }
      );
    },
    spaceAction(keyCode, action) {
      const space = this.setting_mails && this.setting_mails.spacebar_action ? this.setting_mails.spacebar_action : 'down';
      return keyCode === 32 && space === action;
    },
    selectUpDown(e) {
      if (
        !!["ql-editor", "new-tag", "ivu-input","atwhoinput_editor", "keyactable"].find(name => e.target.className.includes(name))
        || (e.target.localName === "input" && e.target.type !== "checkbox")
      ) return;

      if (e.target.className === "atwhoinput_editor" && e.keyCode === 32)
        return;

      if (e.altKey && e.keyCode === 67 && !["atwhoinput_editor"].includes(e.target.className))
        return this.handleFocusComment(e);

      if (e.shiftKey && e.key === 'L') {
        this.restoreLastViewedMail();
        return;
      }

      if (!this.filterList || this.filterList.length == 0)
        return;

      const tableWrap = this.$refs.mailsTableScroller && this.$refs.mailsTableScroller.$el;
      if (!tableWrap) return;

      if (e.shiftKey && e.key === 'D' && this.setting_mails.shortcut_hide_email === 'shift_d') {
        this.markHidden();
        return;
      }
      if (e.keyCode === 46 && this.setting_mails.shortcut_hide_email === 'btn_delete') {
        this.markHidden();
        return;
      }

      if (( e.shiftKey && e.keyCode === 85 && this.setting_mails.shortcut_top_mail_list === 'shift_u') || 
          ( e.ctrlKey && e.keyCode === 36 && this.setting_mails.shortcut_top_mail_list === 'ctrl_home') || 
          ( e.ctrlKey && e.keyCode === 85 && this.setting_mails.shortcut_top_mail_list === 'ctrl_u') ) {
        tableWrap.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }

      if (e.shiftKey) {
        this.handleScrollPreviewByKeys(e);
        return;
      }

      // page up
      if(e.keyCode === 33) {
        e.preventDefault();
        tableWrap.scrollBy({top: -this.pageUpDownDistance, behavior: "smooth"});
        return
      }

      // page down
      if(e.keyCode === 34) {
        e.preventDefault();
        tableWrap.scrollBy({top: this.pageUpDownDistance, behavior: "smooth"});
        return
      }

      if(this.listSelect < 0)
        return;

      if (e.keyCode != 37
        && !this.spaceAction(e.keyCode, 'left')
        && e.keyCode != 39
        && !this.spaceAction(e.keyCode, 'right')
        && e.keyCode != 40
        && !this.spaceAction(e.keyCode, 'down')
        && e.keyCode != 38
        && !this.spaceAction(e.keyCode, 'up'))
      return;

      const commentInput = document.querySelector(".atwhoinput_editor");
      if (commentInput)
        commentInput.innerHTML = "";

      const bodyEl = document.querySelector(".body-wrapper .body-class");
      //----------left
      if (e.keyCode === 37) {
        e.preventDefault();
        bodyEl && bodyEl.scrollBy({top: -200, behavior: "smooth"});
      }

      //----------right
      if (e.keyCode === 39) {
        e.preventDefault();
        bodyEl && bodyEl.scrollBy({top: 200, behavior: "smooth"});
      }

      //----------down
      if (e.keyCode === 40 || this.spaceAction(e.keyCode, 'down')) {
        e.preventDefault();
        this.goUpDownEmail('down', true, !this.autoRead);
      }

      //-----------up
      if (e.keyCode === 38 || this.spaceAction(e.keyCode, 'up')) {
        e.preventDefault();
        this.goUpDownEmail('up', true, !this.autoRead);
      }
    },
    goUpDownEmail(type, scroll = false, withoutRead = false) {
      if (!type) return;

      let index = this.listSelect;
      const tableWrap = this.$refs.mailsTableScroller && this.$refs.mailsTableScroller.$el;

      if (type === 'down') {
        index++;
        index > this.mailList.length - 1 && (index = this.mailList.length - 1)
        scroll && tableWrap.scrollBy({top: this.rowHeight});
      }

      if (type === 'up' && index > 0) {
        index--;
        scroll && tableWrap.scrollBy({top: -1 * this.rowHeight});
      }

      this.selectedRow = this.mailList[index];
      !withoutRead && this.readMail();
    },
    removeAndGoNext(emailIds) {
      this.goUpDownEmail('down');
      this.removeEmailsFromTable(emailIds);
    },
    handleScrollPreviewByKeys(e) {
      const bodyEl = document.querySelector(".body-wrapper .body-class");
      if (!bodyEl) return;

      switch (e.keyCode) {
        case 40: // down
          bodyEl.scrollBy({top: 200, behavior: "smooth"});
          break;
        case 38: // up
          bodyEl.scrollBy({top: -200, behavior: "smooth"});
          break;
        case 37: // left
          bodyEl.scrollBy({left: -150, behavior: "smooth"});
          break;
        case 39: // right
          bodyEl.scrollBy({left: 150, behavior: "smooth"});
          break;
        default:
          break;
      }
      return;
    },
    restoreLastViewedMail() {
      this.autoBookmark && this.gotoBookmark(appConstant.bookmarkType.INVISIBLE);
    },
    handleFocusComment(e) {
      e.preventDefault();
      if (!this.$refs.mailDetail) return;

      if (!this.$refs.mailDetail.$refs.chChat) {
        this.$refs.mailDetail.expand();
      }

      this.$nextTick(()=> {
        const commentInput = document.querySelector(".atwhoinput_editor");
        if (commentInput) return commentInput.focus();
      })
    },
    //-----------------------------handel setting
    removeEmailsFromfolder() {
      if (this.checkGroup.length === 0)
        return;
      this.removeEmailsFromTable(this.checkGroup, [ this.getCacheKey() ]);
      this.updateCurrentEmailList();
      api.delete_email_from_folder({
        email_ids: this.checkGroup,
        folder_id: this.$route_folder_id()
      })
      .then(_ => {
        this.$Message.success(
          `Remove ${this.checkGroup.length} email${
            this.checkGroup.length > 1 ? "(s)" : ""
          } from folder success`
        );
        [this.bookmark, this.autoBookmark].forEach(bookmark => {
          bookmark && bookmark.params.folder_id == this.$route_folder_id()
          && this.checkGroup.findIndex(id => id == bookmark.email_id) > -1
          && this.removeBookmark(bookmark);
        })
        this.clearCheck();
      })
      .catch(error => {
        this.reloadEmailList();
      });
    },
    editFolder() {
      this.$emit("editFolder", this.folder);
    },
    async deleteFolder() {
      const folder = this.folders.selectedFolder;
      this.deleteLoading = true;
      let id = (folder && folder.id) || this.$route_folder_id()
      let params = { id: id };

      try {
        const res = await this.delete_folder({ id, params, folder })
        if (!res)
          return

        this.confirmShow = false;
        this.deleteLoading = false;
        this.$Message.success(`Delete folder successfully!`);
        this.$router.push("/mails");
        [this.bookmark, this.autoBookmark].forEach(bookmark => {
          bookmark && bookmark.folder_id == id && this.removeBookmark(bookmark);
        })
        this.REMOVE_OPEN_TAB({tabName: appConstant.tabPrefixes.FOLDER + id });
      }
      catch(error){
        console.log(error);
        this.deleteLoading = false;
      }
    },
    // delete email(s) by admin
    deleteEmails() {
      if (this.checkGroup.length === 0)
        return;
      this.confirmDeleteMailShow = false;
      this.removeEmailsFromTable(this.checkGroup);
      this.removeMails(this.checkGroup)
        .then(res => {
          this.$Message.success(`Deleted ${this.checkGroup.length} emails successfully`);
          this.clearCheck();
        })
        .catch(error => {
          this.reloadEmailList();
        });
    },
    // Forward as attachment
    forwardMail() {
      const urlObj = {
        path: "sendMail",
        query: {
          forward_id: this.checkGroup.join(',')
        },
        inNewWindow: this.composeInNewWindow
      }

      this.composeMail(urlObj);
      this.checkGroup = [];
    },
    markUnHide(val) {
      const checkGroup = this.checkGroupEmails.filter(el => el.is_hide).map(m => m.id);
      const tmp = union(compact(concat(checkGroup, val)));
      const params = {
        hiddenable_type: 'Email',
        hiddenable_ids: tmp
      };
      this.tableLoading = true;
      api['unhide_batch'](params).then(async () => {
        const checkGroupClone = [...tmp];
        this.UPDATE_MAIL_LIST_ITEMS({
          ids: checkGroupClone,
          changedProps: (_) => ({ is_hide: false })
        })
        this.tableLoading = false;
        this.checkGroup = [];
      }).catch((error) => {
        console.log(error);
      });
    },
    markHidden(emailIds) {
      const selectedRowId = this.selectedRow && !this.selectedRow.is_hide ? this.selectedRow.id : undefined
      const checkGroupIds = this.checkGroupEmails.filter(el => !el.is_hide).map(m => m.id);
      const selectedEmailIds = union(compact(concat(checkGroupIds, emailIds, selectedRowId)));
      if(!selectedEmailIds || selectedEmailIds.length === 0)
        return
      const params = {
        hiddenable_type: 'Email',
        hiddenable_ids: selectedEmailIds
      };
      this.tableLoading = true;
      api['hidden_batch'](params)
        .then(async () => {
          const checkGroupClone = [...selectedEmailIds];
          if (this.showHiddenEmail) {
            this.UPDATE_MAIL_LIST_ITEMS({
              ids: checkGroupClone,
              changedProps: (_) => ({ is_hide: true }),
            })
          this.goUpDownEmail('down', true);
          } else {
            this.goUpDownEmail('down');
            this.removeEmailsFromTable(checkGroupClone);
          }
        })
        .catch(_ => {})
        .finally(_ => {
          this.tableLoading = false;
          this.clearCheck();
        })
    },
    readEmails() {
      if (this.checkGroup.length === 0) return;
      const checkGroupClone = [...this.checkGroup];
      this.tableLoading = true;
      this.checkGroup.length > 10 && this.$Message.info('This process may take several minutes to update')
      api
        .mark_as_reads({
          email_ids: this.checkGroup,
        })
        .then(res => {
          const linesIdMap = {};
          this.lines_all.lines.forEach((line, index) => {
            linesIdMap[line.id] = index;
          });
          this.UPDATE_MAIL_LIST_ITEMS({
            ids: checkGroupClone,
            changedProps: (_) => ({ read: true })
          })
          this.tableLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.tableLoading = false;
        });
      this.clearCheck()
    },
    async onUnSpamEmails(emailIds) {
      this.removeEmailsFromTable(emailIds, [ this.getCacheKey() ]);
      try {
        await this.unSpamEmails(emailIds)
        this.$Message.success(`Unspam ${emailIds.length} emails successfully`);
        this.clearCheck();
      } catch (error) {
        this.reloadEmailList();
      }
    },
    // Tag email
    openTagEml() {
      this.$refs.tagEmails.show();
    },
    //-------------------------------------star
    async starFolder() {
      try {
        const params = {
          starable_id: parseInt(this.$route_folder_id()),
          starable_type: "Folder"
        };
        await this.star_folder({ params, isStar: true, folder: this.folder });
        this.folder.star = true;
      }
      catch(error) {
        console.log(error);
      }
    },
    async unStarFolder() {
      try {
        const params = {
          starable_id: parseInt(this.$route_folder_id()),
          starable_type: "Folder"
        };
        await this.star_folder({ params, isStar: false, folder: this.folder });
        this.folder.star = false;
      }
      catch(error) {
        console.log(error);
      }
    },
    async handleRead(para) {
      if (para && para.rowId) {
        this.markAsRead(para.rowId)
        this.readEmail(para);
      }
    },
    markAsRead(id) {
      this.UPDATE_MAIL_LIST_ITEMS({
        ids: [id],
        changedProps: (_) => ({ read: true })
      })
    },
    abortSend() {
      if (this.checkGroup.length === 0)
        return this.$Message.error("You have not selected any emails to abort send.");

      let checkEmails = [];
      this.checkGroup.forEach(id => {
        checkEmails.push(this.mailList.find(item => item.id === id));
      });
      if (checkEmails.every(item => item.status === "delay" || item.status === "schedule")) {
        this.$refs.abort.show();
      } else {
        this.$Message.error("Only delayed or scheduled emails can be aborted.");
      }
    },
    showFolderInfo() {
      this.$refs.folderDetail.show(this.$route_folder_id());
    },
    updateComments(number, id) {
      this.UPDATE_MAIL_LIST_ITEMS({
        ids: [id],
        changedProps: (_) => ({ comments_count: number })
      })
    },
    updateTags(tag, id, deleted) {
      this.UPDATE_MAIL_LIST_ITEMS({
        ids: [id],
        changedProps: (email) => {
          if (deleted) {
            return { tags: email.tags.filter(item => item !== tag) };
          }

          return { tags: [...new Set([...email.tags, tag])] }
        }
      })
    },
    massUpdateTags(tags) {
      this.UPDATE_MAIL_LIST_ITEMS({
        ids: [...this.checkGroup],
        changedProps: (email) => ({ tags: email.tags.concat(tags) })
      })
      this.checkGroup = [];
    },
    closePreviewDetail(option) {
      this.prevRowHeight = this.rowHeight;
      this.prevScrollerHeight = this.$refs.mailsTableScroller.$el.offsetHeight;
      this.prevScrollTop = this.$refs.mailsTableScroller.$el.scrollTop;

      if(option && option.reloadEmailList)
        this.refreshEmailsWithDelay();

      if(
        (option && option.deleteEmail && this.selectedRow)
        || (this.isStarredList && this.selectedRow && !this.selectedRow.star)
      ) {
        if (!this.mailList[this.listSelect]) return;

        this.DELETE_MAIL_LIST_ITEMS({
          ids: [this.mailList[this.listSelect]].map(i => i.id),
          currentCacheKey: this.getCacheKey()
        })
        this.totalNum--;
      }

      this.previewing = false;
      this.handleColumnSize();

      this.$nextTick(() => {
        this.allowScrollToPrevPosition = true;
        this.scrollToPrevPosition();
      })
    },
    onEmailShiftClick(email, index){
      if(this.lastSelectIndex == -1){
        this.lastSelectIndex = index;
        const currentRow = this.$refs[`emailRow${email.id}`];
        currentRow && currentRow.clickCheckbox(email.id);
        return;
      }

      const selected = this.checkGroup.findIndex(e => e == email.id) >= 0;
      const minIndex = this.lastSelectIndex < index ? this.lastSelectIndex : index;
      const maxIndex = this.lastSelectIndex < index ? index : this.lastSelectIndex;

      for(let i = minIndex; i <= maxIndex; i++){
        const emailId = this.filterList[i].id;
        const existedIndex = this.checkGroup.findIndex(e => e == emailId);
        !selected && existedIndex == -1 && this.checkGroup.push(emailId);
        selected && existedIndex >= 0 && this.checkGroup.splice(existedIndex, 1);
      }
      this.lastSelectIndex = index;
    },

    onEmailClick(index) {
      this.lastSelectIndex = index
    },

    async onStarClick(selected, mailIds) {
      if(!mailIds || mailIds.length === 0) 
        return

      if(mailIds.length > 100) {
        this.$Message.error('Emails quantity exceeds maximum quantity limit of 100 emails')
        return
      }

      this.UPDATE_MAIL_LIST_ITEMS({
        ids: [...mailIds],
        changedProps: (_) => ({ star: selected })
      })

      if (!this.isStarredList) {
        util.resetCachedEmails("is_star=");
      } else {
        if (selected) return;
        this.removeEmailsFromTable(mailIds, [ this.getCacheKey() ]);
      }

      try {
        await this.starMail({selected, mailIds});
      } catch(_) {
        this.reloadEmailList();
      }
    },

    onClosePopupFromAside() {
      this.onCloseDetailPopupAfterStar([this.selectedRow.id])
    },

    onClosePopupFromMailDetail(event) {
      if(!event || !event.detail) return
      event.detail.id && this.onCloseDetailPopupAfterStar([event.detail.id], () => this.closePreviewDetail())
      if(event.detail.action === 'delete_draft' && event.detail.id && this.isDraftList) {
        this.removeEmailsFromTable([parseInt(event.detail.id)], [this.getCacheKey()])
      }
    },
    setDirection() {
      const params = {
        keys: ['conversation_view'],
        values: [this.previewVertical]
      };
      api
        .set_user_settings(params)
        .then(res => {
          this.SET_MAILS_SETTINGS({
            conversation_view: `${this.previewVertical}`,
          });
        })
    },
    onChangeDirection(val) {
      this.previewVertical = val
      this.setDirection();
      this.scrollToRow(this.listSelect);
    },
    scrollToPrevPosition() {
      let scroller = this.$refs.mailsTableScroller;
      if (!scroller || !this.selectedRow) return;
      scroller = scroller.$el;
      if (scroller) {
        const index = this.mailList.findIndex(email => email.id === this.selectedRow.id);
        const rowHeightDiff = index * (this.rowHeight - this.prevRowHeight);

        if (this.previewVertical) {
          scroller.scrollTop = this.prevScrollTop + rowHeightDiff;
          return;
        }
        const scrollerHeightRatio = scroller.offsetHeight / this.prevScrollerHeight;
        const rowDistanceToTop = index * this.rowHeight;

        scroller.scrollTop = (this.prevScrollTop + rowHeightDiff) / scrollerHeightRatio ;

        scroller.scrollTop = rowDistanceToTop - (rowDistanceToTop-this.prevScrollTop) * scrollerHeightRatio;

        if (scroller.offsetHeight - (rowDistanceToTop - this.prevScrollTop) < this.rowHeight) {
          scroller.scrollTop += this.rowHeight / 2
        }
      }
    },
    scrollToEmailId(emailId) {
      const index = this.mailList.findIndex(email => email.id === emailId);
      index > -1 && this.scrollToRow(index);
    },
    scrollToRow(rowIndex) {
      this.$nextTick(() => {
        if (rowIndex < 0 || !this.$refs.mailsTableScroller)
          return;
        let scroller = this.$refs.mailsTableScroller.$el;
        scroller && (scroller.scrollTop = rowIndex * this.rowHeight);
      })
    },
    onCloseDetailPopupAfterStar(mailIds, callback) {
      const isStarMail = JSON.parse(localStorage.getItem(LocalStorageConstant.UPDATE_STAR_MAIL));
      localStorage.removeItem(LocalStorageConstant.UPDATE_STAR_MAIL);
      !util.isNullOrUndefined(isStarMail) && this.setStarStatus({ mailIds: mailIds, selected: isStarMail })
      if(this.isStarredList && isStarMail == false) {
        this.removeEmailsFromTable(mailIds, [ this.getCacheKey() ]);
        callback && callback();
      }
    },
    getUsernames(apiTo) {
      let transformedName = apiTo;
      let transformedTooltip = apiTo;
      if (Array.isArray(apiTo)) {
        if (apiTo.length > 0) {
          const to = apiTo.map(item => this.handleSyncItem(item));
          transformedName = to.map(to => to.username).join(", ");
          transformedTooltip = to.map((to, index) => `${index + 1}: ${to.username.trim()} (${to.address.trim()})`).join("\n");
        } else {
          transformedName = "";
          transformedTooltip = "";
        }
      }
      return { transformedName, transformedTooltip }
    },
    getTooltipTags(tags) {
      return tags.map((tag, index) => `${index + 1}: ${tag.trim()}`).join("\n");
    },
    handleArchiveFromContextMenu(ids) {
      this.$Modal.confirm({
        title: 'Confirmation',
        content: `<p style="margin-left:-42px">Are you sure you want to archive ${ids.length} emails?</p>`,
        onOk: async () => {
          this.handleArchive(ids)
        }
      });
    },
    async handleArchive(ids) {
      util.resetCachedEmails("archived=");
      if (!ids) ids = this.checkGroup

      this.confirmArchiveMailShow = false;
      this.removeEmailsFromTable(ids, [ this.getCacheKey() ]);
      try {
        await this.archive([...ids])
        this.$Message.success(`Archived Email Success!`);
        this.clearCheck();
      } catch (error) {
        this.reloadEmailList();
      }
    },
    async handleUnarchive(ids) {
      if (!ids) ids = this.checkGroup

      this.confirmArchiveMailShow = false;
      this.removeEmailsFromTable(ids, [ this.getCacheKey() ]);
      try {
        await this.unarchive(ids);
        this.$Message.success(`Unarchived Email Success!`);
        this.clearCheck();
      } catch (error) {
        this.reloadEmailList();
      }
    },
    onItemRightClick(event, mailItem, index) {
      this.$nextTick(() => {
        this.$refs.mailItemContextmenu.closeMenu();
        !mailItem.assignment_ids && (mailItem.assignment_ids = []);
        this.$refs.mailItemContextmenu.shown = true;
        this.$refs.mailItemContextmenu.top = event.clientY;
        this.$refs.mailItemContextmenu.left = event.clientX;
        this.selectedRow = this.mailList[index];
        const multipleChoices = this.checkGroup.findIndex(id => id == mailItem.id) > -1; // if right click on multiple selected rows.
        this.$refs.mailItemContextmenu.emails = !multipleChoices
          ? [mailItem]
          : this.mailList.filter(e => this.checkGroup.findIndex(id => id == e.id) > -1)
        this.checkGroup = !multipleChoices ? [mailItem.id] : this.checkGroup;
      })
    },

    handleHotkey(e) {
      if((e.ctrlKey || e.metaKey) && e.key == 'a') {
        if(document.activeElement.nodeName != 'BODY'
          && document.activeElement.className
          && !document.activeElement.className.includes('home-page-table-mail'))
          return;
        e.preventDefault();
        this.onSelectAll();
      }
      if(e.key == 'q' || (e.ctrlKey && e.key == 'q') || (e.metaKey && e.key == 'q')) {
        e.preventDefault();
        this.checkGroup && this.checkGroup.length > 0 && this.readEmails();
      }
      if(e.key == 'Escape' || e.keyCode == 27) {
        e.preventDefault();
        this.closeMailContextMenu();
        this.closeMailFolderPoptip();
      }
    },
    async showMailPreviewBody() {
      this.previewMailBody = !this.previewMailBody;
      util.resetCachedEmails(this.getCacheKey());
      await this.updatePreviewMailSetting(this.previewMailBody);
      this.previewMailBody && this.reloadEmailList();
    },
    onContextMenuClose() {
      if(!this.previewing) {
        this.selectedRow = null;
      }
    },
    async changeAssignmentStatus() {
      if(this.checkGroup.length == 0) return;
      const reopen = this.currentTabName == appConstant.mailTabs.CLOSED;
      this.UPDATE_MAIL_LIST_ITEMS({
        ids: [...this.checkGroup],
        changedProps: (_) => ({ closed: !reopen })
      })
      try {
        this.currentTabName !== appConstant.mailTabs.ALL && this.removeEmailsFromTable(this.checkGroup, [ this.getCacheKey() ]);
        !reopen && await api.markAsClosedBatch(this.checkGroup);
        reopen && await api.reopenBatch(this.checkGroup);
        this.clearCheck();
      } catch(e) {
        this.reloadEmailList();
      }
    },
    refreshEmailsWithDelay() {
      setTimeout(() => {
        util.resetCachedEmails(this.getCacheKey());
        this.reloadEmailList();
      }, 500)
    },
    scrollToLoadMore() {
      const mailTableEl = this.$refs['mailsTableScroller'];
      mailTableEl && util.scrollToLoadMore(mailTableEl.$el, {
        bottomDistance: 50,
        scrollToBottomCallback: () => this.loadMore(),
        topDistance: 0,
        scrollToTopCallback: () => this.loadPrevious()
      });
    },
    loadMore() {
      if(this.loadingMore || !this.hasMoreItems || this.mailList.length >= this.maximumLoadedEmails)
        return;
      this.loadingMore = true;
      this.loadEmailList({scrollTo: scrollToTypes.DOWN}, false);
    },
    loadPrevious() {
      if(this.loadingPrevious
        || this.mailList.length == 0
        || (!this.hasPrevItems && (!this.emailParams || !this.emailParams.bookmark)))
        return;
      this.loadingPrevious = true;
      this.loadEmailList({scrollTo: scrollToTypes.UP}, false);
    },
    updateEmailListAfterDrag(dragEmails=[], reload) {
      this.DELETE_MAIL_LIST_ITEMS({
        ids: [...dragEmails],
        currentCacheKey: this.getCacheKey()
      })
      if (reload) this.reloadEmailList();
    },
    getHighlights(type) {
      if(!this.highlights || this.highlights.length == 0)
        return [];
      let keywords = [];
      const items = this.highlights.filter(i => !!i[type])
      items && items.map(i => {
        i[type] && Array.isArray(i[type]) && keywords.push(...i[type])
        i[type] && !Array.isArray(i[type]) && keywords.push(i[type])
      })
      return Array.from(new Set(keywords));
    },
    buildTextWithHighlight(keywords, text) {
      let t = text || "";
      t = t.replaceAll('<', '&lt;').replaceAll('>', '&gt;');
      if(!keywords || keywords.length == 0) return t;

      const prefix = "<span class='text-highlight'>"
      const postfix = "</span>"
      const highlightRegEx = /(<span class='text-highlight'>[^<]*<\/span>)/gim;
      let modifiedText = t;
      let words = [];
      let keywordRegEx, keywordIndex, originalWord, valid, keywordLength;
      keywords.forEach(keyword => {
        if(!keyword)
          return;
        keywordLength = keyword.length;
        keyword = util.escapeSpecialCharacters(keyword);
        keywordRegEx = new RegExp(keyword, 'gim');
        words = modifiedText.split(highlightRegEx);
        words.forEach((textWord, index) => {
          if(!textWord)
            return;
          keywordIndex = textWord.search(keywordRegEx);
          valid = !textWord.match(highlightRegEx) && keywordIndex != -1;
          if(!valid)
            return;
          originalWord = textWord.substr(keywordIndex, keywordLength);
          words[index] = words[index].replace(keywordRegEx, `${prefix}${originalWord.replace('<', '&lt;').replace('>', '&gt;')}${postfix}`)
          modifiedText = words.join("");
        })
      })
      return modifiedText;
    },
    closeMailContextMenu() {
      this.$refs.mailItemContextmenu && this.$refs.mailItemContextmenu.closeMenu();
    },
    gotoBookmark(type = appConstant.bookmarkType.DEFAULT) {
      this.goingToBookmark = true;
      const bookmark = type === appConstant.bookmarkType.DEFAULT ? this.bookmark : this.autoBookmark;
      
      if (!bookmark) {
        this.goingToBookmark = false
        return;
      }

      const { me, archived, is_star, status, box, folder_id, folder_name, line_id, is_mail_activity, tab, current_path } = bookmark.params;

      if(current_path) {
        if(!appConstant.bookmarkRedirectPath.includes(current_path?.split('?')[0])) return;
        const path = util.parseUrl(current_path).addQueryParam("bm", type === appConstant.bookmarkType.DEFAULT ? "0" : "1");

        if (this.$route.fullPath !== path ) {
          this.$router.push(path);
          return;
        }

        const bookmarkIndex = this.mailList.findIndex(email => email.id === bookmark.email_id);
        if(bookmarkIndex != -1) {
          this.scrollToRow(bookmarkIndex);
          this.goingToBookmark = false;
          return;
        }
        this.$route.query.rand = Date.now();
        this.$router.push(this.$route.fullPath);

        return;
      }

      let url = '/mails';
      if(me) {
        url += '?me=true';
      }
      if(is_star) {
        url += '?is_star=true';
      }
      if(archived) {
        url += '?archived=true';
      }
      if(box) {
        url += `?box=${box}`;
      }
      if(status == 'sent') {
        url += '?status=sent';
      }
      if(status == 'pending' && box) {
        url += '&status=pending';
      }
      if(folder_id && folder_name) {
        url += `?folder_id=${folder_id}&folder_name=${folder_name}`;
      }
      let syntax = url === '/mails' ? '?' : '&';
      const validLineId = this.lines_all.lines.findIndex(line => line.id == line_id) != -1;
      if(validLineId) {
        url += `${syntax}line_id=${line_id}`;
        syntax = '&';
      }
      if(is_mail_activity) {
        const tabName = this.p_enabled_assignment
          ? this.bookmark.closed ? 'closed' : 'open'
          : null;
        tabName && (url += `${syntax}tab=${tabName}`);
      }
      if(this.$route.fullPath != url) {
        this.$router.push(url);
        return;
      }
      const bookmarkIndex = this.mailList.findIndex(email => email.id === this.bookmark.email_id);
      if(bookmarkIndex != -1) {
        this.scrollToRow(bookmarkIndex);
        this.goingToBookmark = false;
        return;
      }
      this.reloadEmailListWithBookmark(this.bookmark.email_id);
    },
    refreshSearchTime() {
      const unixtimeStr = new Date().getTime().toString();
      return parseFloat(unixtimeStr.substring(0, 10) + '.' + unixtimeStr.substring(10));
    },
    onBookmark(email) {
      this.bookmark && this.bookmark.email_id == email.id && this.onRemoveBookmark();
      (!this.bookmark || this.bookmark.email_id !== email.id) && this.onAddBookmark(email);
    },
    async onAddBookmark(email) {
      if(this.bookmarking)
        return;
      this.bookmarking = true;
      const bookmark = {
        bookmark_type: appConstant.bookmarkType.DEFAULT,
        closed: email.closed,
        email_id: email.id,
        line_id: email.line_id,
        params: {
          folder_id: this.emailParams.folder_id,
          folder_name: this.$route_folder_name(),
          box: this.emailParams.box,
          line_id: this.emailParams.line_id,
          archived: this.emailParams.archived,
          status: this.emailParams.status,
          is_star: this.emailParams.is_star,
          me: this.emailParams.me,
          is_mail_activity: !this.emailParams.folder_id
            && !this.emailParams.box
            && !this.emailParams.archived
            && !this.emailParams.status
            && !this.emailParams.is_star
            && !this.emailParams.me,
          current_path: util.parseUrl(this.$route.fullPath).removeQueryParam("bm")
        }
      }
      const res = await this.addBookmark(bookmark);
      this.bookmarking = false;
    },
    async onRemoveBookmark() {
      if(this.bookmarking)
        return;
      this.bookmarking = true;
      await this.removeBookmark(this.bookmark);
      this.bookmarking = false;
    },
    getAssignmentColumnWidth() {
      const columnWidthProperty = getComputedStyle(document.documentElement).getPropertyValue('--assignment-td-width');
      return columnWidthProperty && parseInt(columnWidthProperty) || 0;
    },
    onPreviewResizeDone(data) {
      this.previewSize = data;
    },
    toggleEmailFiling() {
      this.emailFilingShown = !this.emailFilingShown;
      if(!this.emailFilingShown) return;
      this.$nextTick(_ => {
        this.emailFilingPosition = this.computePosition(
          this.$refs.iconEmailFiling,
          this.$refs.emailFiling.$el,
          'bottom',
          { top: 8, right: 4, left: 4, bottom: 16 }
        );
      })
    },
    onEmailFiled(folders) {
      this.closeEmailFiling();
      if(!folders || folders.length == 0)
        return;
      this.UPDATE_MAIL_LIST_ITEMS({
        ids: [...this.checkGroup],
        changedProps: (_) => ({ filed: true })
      })
    },
    closeEmailFiling() {
      this.emailFilingShown = false;
    },
    onHideDateIndicator() {
      this.dateIndicatorShown = false;
      this.removeDate();
    },
    removeDate() {
      this.filter.received_at = undefined;
      this.filter.received_at_range = undefined;
      this.dateShortcut = undefined;
      this.enterFilter();
    },
    async onMarkAsSpam(emailIds) {
      emailIds = emailIds || this.checkGroup;

      const selectedEmails = this.filterList.filter(mail => emailIds.includes(mail.id));
      const hasSentEmail = selectedEmails.some(mail => mail.status === "sent");
      emailIds = selectedEmails.filter(mail => mail.status !== "sent").map(m => m.id)

      this.tableLoading = true;

      const _markAsSpam = async () => {
        try {
          util.resetCachedEmails("box=spam");

          this.removeAndGoNext(emailIds)

          await this.markAsSpam(emailIds);
          this.$Message.success(`Mark ${emailIds.length} email${emailIds.length > 1 ? 's' : ''} as spam successfully`);
          this.clearCheck();
        } catch (error) {
          this.reloadEmailList();
        } finally {
          this.tableLoading = false;
        }
      }

      if (hasSentEmail && emailIds.length === 0) {
        this.tableLoading = false;
        return this.$Message.error("Sent email cannot be marked as spam");
      }

      if (hasSentEmail && emailIds.length > 0) {
        return this.$Modal.confirm({
          title: "Warning",
          content: `<p style="margin-left:-42px">Sent email cannot be marked as spam. Do you want to apply the change to other emails</p>`,
          okText: "Continue",
          onCancel: () => {
            this.tableLoading = false;
          },
          onOk: _markAsSpam
        });
      }

      _markAsSpam();
    },
    removeEmailsFromTable(emailIds, emailGroupKey = [this.getCacheKey()]) {
      this.previewing
        && this.selectedRow
        && emailIds.findIndex(id => id == this.selectedRow.id) > -1
        && this.closePreviewDetail();

      this.DELETE_MAIL_LIST_ITEMS({
        ids: [...emailIds],
        changedProps: (item) => ({ total: item.total - 1 }),
        currentCacheKey: this.getCacheKey(),
        includeKeys: emailGroupKey
      })

      this.totalNum = this.totalNum - emailIds.length;

      this.mailList.length === 0 
        && this.totalNum > 0 
        && this.refreshEmailsWithDelay()
    },
    mailDragDone(mailDragged) {
      this.clearCheck();
      if(this.hideFiled) {
        this.DELETE_MAIL_LIST_ITEMS({
          ids: [...mailDragged],
          currentCacheKey: this.getCacheKey()
        })
        this.$nextTick(_ => {
          const scroller = this.$refs.mailsTableScroller && this.$refs.mailsTableScroller.$el;
          scroller && scroller.scrollHeight <= scroller.clientHeight && this.reloadEmailList();
        })
        return;
      }
      this.UPDATE_MAIL_LIST_ITEMS({
        ids: [...mailDragged],
        changedProps: (_) => ({ filed: true })
      })
    },
    getCacheKey(r) {
      const route = r ? r : this.$route;
      let key = "uid";
      let { box, status, archived, folder_id, tab, subtab, 
        openMode, line_id, is_star, me, a, bookmark,
        live_feed_id 
      } = route.query;
      let tabStr = ['me', 'closed'].includes(subtab) ? subtab : ''

      if (this.currentTabName === 'open') {
        tabStr = openMode
      }

      if (tab) {
        folder_id = "";
        line_id = "";
        if (/lf/.test(tab)) {
          live_feed_id = tab.split("lf_")[1]
        }
        if (/fid/.test(tab)) {
          folder_id = tab.split("fid_")[1]
        }
        if (/line/.test(tab)) {
          let [_, lineId, origin] = tab.split("_");
          line_id = lineId;

          switch (true) {
            case origin === "mails":
              break;
            case origin === "sent":
              status = "sent"
              break;
            case origin === "archived":
              archived = true
              break;
            case origin === "outbox":
              box = "Sent";
              status = "pending";
              break;
            default:
              box = origin;
              break;
          }
        }
      }

      return key + (this.usingSearch ? `usingSearch=${this.usingSearch}` : "") +
        (box ? `box=${box.toLowerCase()}` : "") +
        (status ? `status=${status.toLowerCase()}` : "") +
        (archived ? `archived=${archived}` : "") +
        (tabStr ? `tabStr=${tabStr}` : "") +
        (folder_id ? `folder_id=${folder_id}` : "") +
        (line_id ? `line_id=${line_id}` : "") +
        (live_feed_id ? `live_feed_id=${live_feed_id}` : "") +
        (is_star ? `is_star=${is_star}` : "") +
        (me ? `me=${me}` : "") +
        (a ? `a=${a}` : "") +
        (bookmark ? `bookmark=${bookmark}` : "")
    },
    initializeCache(key, query) {
      const k = key || this.getCacheKey();
      const q = query || this.$route.query;

      if (!util.routeBasedEmails[k]) {
        util.routeBasedEmails[k] = {
          query: {...q},
          total: 0,
          emailMap: new Map(),
          emails: [],
          extraOptions: {}
        };
        util.routeBasedEmailKeys.unshift(k);
      }
    },
    updateCurrentEmailList() {
      if (this.usingSearch || this.tableLoading || this.loadingMore || this.loadingPrevious || this.goingToBookmark) return;

      const currentRes = util.routeBasedEmails[this.getCacheKey()];
      if (!currentRes) return;

      this.totalNum = currentRes.total < currentRes.emailMap.size ? currentRes.emailMap.size : currentRes.total;

      let emailMap = currentRes.emailMap;
      if (!emailMap) return;

      this.sortEmails(currentRes);

      this.UPDATE_MAIL_LIST({
        emails: [...currentRes.emailMap.values()],
        key: this.getCacheKey()
      });
    },
    sortEmails(currentRes) {
      currentRes.emailMap = new Map([...currentRes.emailMap.entries()].sort((x, y) => this.sortCallback(x[1], y[1])))
    },
    sortCallback(x, y) {
      const num = this.sortMethod === "asc" ? -1 : 1;
      return num * (new Date(y.received_at) - new Date(x.received_at));
    },
    reloadIfNewVersion() {
      let href = window.location.href;
      const versionQuery = "_newVersion=true"
      if (href.includes(versionQuery)) {
        href = href.replace(`${versionQuery}&`, "")
                   .replace(versionQuery, "")
        setTimeout(() => {
          window.location.href = href;
          window.location.reload(true);
        }, 1000)
      }
    },
    onAssignTabClick(tab, openMode) {
      util.resetCachedEmails(this.getCacheKey());

      this.currentTabName = tab.name

      openMode && localStorage.setItem(LocalStorageConstant.OPEN_MODE, openMode);

      const routeData = {
        path: this.$route.path,
        query: {
          ...this.$route.query,
          subtab: tab.name,
          openMode
        }
      }
      this.$router.replace(routeData).catch(() => {});
    },
    handleClickOpenButton(event, tab) {
      if (this.currentTabName !== 'open' && tab.name === 'open') {
        event.preventDefault();
        event.stopPropagation();
        this.onAssignTabClick(tab, this.assignTabOpenMode);
      }
    },
    onHideFiledChange(val) {
      util.resetCachedEmails(this.getCacheKey());
      this.reloadEmailList();
      !this.isQuickSearch && localStorage.setItem(LocalStorageConstant.HIDE_FILED, val);
    },
    onShowHidden(val) {
      util.resetCachedEmails(this.getCacheKey());
      this.reloadEmailList();
      !this.isQuickSearch && localStorage.setItem(LocalStorageConstant.SHOW_HIDDEN_EMAIL, val);
    },
    onHideAssignedChange(val) {
      util.resetCachedEmails(this.getCacheKey());
      this.reloadEmailList();
      !this.isQuickSearch && localStorage.setItem(LocalStorageConstant.HIDE_ASSIGNED, val);
    },
    handleMailTabs() {
      this.currentTabName = this.assignmentTabs.ALL.name
      if(this.isShowAssignmentTabs) {
        const defaultTabName = this.isAdvancedSearch ? this.assignmentTabs.ALL.name : this.assignmentTabs.OPEN.name
        this.currentTabName = this.$route.query.subtab || defaultTabName
        this.assignmentTabs.ALL.hide = !this.isAdvancedSearch
      }
    },
    handleConfigurationData() {
      this.showHiddenEmail = this.isQuickSearch ? false : localStorage.getItem(LocalStorageConstant.SHOW_HIDDEN_EMAIL) === "true"
      this.hideFiled = this.isQuickSearch ? false : localStorage.getItem(LocalStorageConstant.HIDE_FILED) === "true"
      this.hideAssigned = this.isQuickSearch ? false : localStorage.getItem(LocalStorageConstant.HIDE_ASSIGNED) === "true"
      this.selectedList = this.isQuickSearch || this.isDraftList || this.isOutboxList ? 'all' : (localStorage.getItem(LocalStorageConstant.READ_OR_UNREAD) || "all")
    },
    shouldNotAppendEmailToLiveFeed(tabQuery="") {
      return tabQuery.includes("lf_") && !this.tabsToAlert.includes(tabQuery);
    },
    getMailBoxes(lineIds) {
      if(!lineIds || lineIds.length == 0) 
        return {items: [], tooltip: ''}

      let lines = this.lines_all.full_lines || this.lines_all.lines || [];
      lines = lines.filter(l => lineIds.findIndex(id => id === l.id) > -1)
      lines = lines.map(l => {
        return {
          id: l.id,
          display_name: l.display_name,
          username: l.username || '',
          colour: l.colour
        }
      })
      return {
        items: lines,
        tooltip: lines.map((line, index) => `${index + 1}: ${line.username.trim()}`).join("\n")
      }
    },
    onMailFilterChange(data) {
      this.dateShortcut = data.date_shortcut
      Object.assign(this.filter, data)
      delete this.filter.date_shortcut
      this.enterFilter()
    },
    onFilterClick(type) {
      this.showFilter = true
      this.$nextTick(_ => {
        this.$refs.mailFilterRef && this.$refs.mailFilterRef.toggleFilter(type)
      })
    }, 
    initFilterValues() {
      this.filter = JSON.parse(JSON.stringify(this.defaultFilterValue))
    },
    async onMarkAsRead(emailIds) {
      if(!emailIds || emailIds.length === 0) return
      try {
        this.UPDATE_MAIL_LIST_ITEMS({
          ids: emailIds,
          changedProps: (_) => ({ read: true })
        })
        emailIds.length > 1 ? await api.mark_as_reads({email_ids: emailIds}) : await api.read_email(emailIds[0])
        this.$Message.success(`${emailIds.length} email${emailIds.length === 1 ? '' : 's'} marked as read`);
        this.clearCheck();
      } catch (error) {
        this.reloadEmailList();
      }
    },
    async onMarkAsUnread(emailIds) {
      if(!emailIds || emailIds.length === 0 || emailIds.length > 1) return
      try {
        await api.mark_as_unread({email_id: emailIds[0]})
        this.$Message.success(`${emailIds.length} email${emailIds.length === 1 ? '' : 's'} marked as unread`);
        this.UPDATE_MAIL_LIST_ITEMS({
          ids: emailIds,
          changedProps: (_) => ({ read: false })
        })
        this.clearCheck();
      } catch (error) {
        this.reloadEmailList();
      }
    },
    onOpenFolderPoptipMenu(event, id) {
      this.getFolderData(id);
      this.$nextTick(() => {
        this.$refs.mailFolderPoptipMenu.closeMenu();
        this.$refs.mailFolderPoptipMenu.shown = true;
        this.$refs.mailFolderPoptipMenu.position.top = event.clientY;
        this.$refs.mailFolderPoptipMenu.position.left = event.clientX;
      })
    },
    async getFolderData(id) {
      try {
        this.mailFolderLoading = true;
        const res = await api.get_email_folders_data(id);
        this.listFolder = res?.folders;
      } catch (error) {
        console.log(error);
        this.$Message.error("Failed to load folder.");
      } finally {
        this.mailFolderLoading = false;
      }
    },
    closeMailFolderPoptip() {
      this.$refs.mailFolderPoptipMenu && this.$refs.mailFolderPoptipMenu.closeMenu();
    },
    onFolderContextMenuClose() {},
    async loadTrigonalCapabilities() {
      if(!this.p_enable_trigonal) 
        return

      const res = await api.get_trigonal_capabilities().catch(_ => {})
      if(!res) 
        return

      let capabilities = []
      Object.keys(res).forEach(key => {
        if(key && key != 'id' && res[key]) {
          const label = insertSpaceBetweenUpper(key.substring(3)) || key // e.g. key = 'hasPositionQuickUpdate'
          capabilities.push({
            label,
            value: key
          })
        }
      })
      this.trigonalCapabilities = capabilities
    },
    async onTrigonalCapClick(item) {
      this.trigonalLoading = true
      try {
        const emailIds = concatWithoutDuplicates(this.checkGroup, this.selectedRow ? [this.selectedRow.id] : [])
        await api.trigonal_filing({
          email_ids: emailIds,
          capability_type: item.value
        })
        this.clearCheck()
      }
      catch (_) { } 
      finally {
        this.trigonalLoading = false
      }
    }
  },
  filters: {
    formatData(GMT) {
      let timeString = util.relativeDay(GMT) + ", " + util.time_12(GMT);
      return timeString;
    },
    convertToText(html) {
      return util.removeSearchHtml(html);
    }
  },
  computed: {
    ...mapGetters([
      "folders",
      "mailAdvance",
      "setting_mails",
      "userInfo",
      "teamMembers",
      "setting_company",
      "bookmark",
      "autoBookmark",
      "mailList",
      "activeTab",
      "inappMailState",
      "isOpeningInappWindow",
      "previewExpanded",
      "liveFeeds",
      "landingToLastSeenEmail"
    ]),
    ...mapState([
      "online",
      "lines",
      "lines_all",
    ]),

    isChangedReadAndUnread() {
      return this.selectedList !== "all";
    },

    isShowStar() {
      return !this.isSpamList && this.mailList.findIndex(item => !item.star && this.checkGroup.includes(item.id)) != -1;
    },

    isShowUnstar() {
      return !this.isSpamList && this.mailList.findIndex(item => item.star && this.checkGroup.includes(item.id)) != -1;
    },

    isStarredList() {
      return this.$route.query.is_star == 'true';
    },

    isSpamList() {
      return this.$route_box() && this.$route_box().toLowerCase() === 'spam';
    },
    isArchivedList() {
      return !!this.$route_archived();
    },
    isOutboxList() {
      return this.$route_box() && this.$route_box().toLowerCase() === 'sent' && this.$route_status() == 'pending';
    },
    isDraftList() {
      return this.$route_box() && this.$route_box().toLowerCase() === 'draft';
    },
    isSentList() {
      return this.$route_status() == 'sent';
    },
    searching() {
      return this.$route.query.search_key || this.$route.query.search;
    },
    usingSearch() {
      return this.searching || this.hasFilterChange;
    },
    // permission for edit share group
    p_edit_share() {
      if(this.folder && !this.folder.personal)
        return this.checkPermission("create_and_edit_shared_folders");
      return !!this.$route_folder_id();
    },
    // permission for delete share group
    p_delete_share() {
      if(this.folder && !this.folder.personal)
        return this.checkPermission("delete_shared_folders");
      return this.$route_folder_id();
    },
    // permission for delete share group
    p_mark_spam() {
      if (!this.userInfo.user) return false;
      return this.checkPermission("mark_email_as_spam");
    },
    // permission for delete email
    p_delete_mail() {
      if (!this.userInfo.user) return false;

      if (this.userInfo.user.user_type === "admin") return true;

      const userCreatedIds = this.checkGroupEmails.map(email => email.user_id);
      const set = new Set([...userCreatedIds]);
      if (set.size !== 1) return false;
      if (set.has(+this.userInfo.user.id)) return true;

      return false;
    },
    // permision to assign email to the same company members.
    p_enabled_assignment() {
      return this.setting_company.assignment_module == '1'
    },
    p_mark_as_unread() {
      return this.setting_company.mark_as_unread == '1'
    },
    // permission to archive email
    p_enabled_archive() {
      return this.setting_company.enable_archive == '1'
    },
    p_enable_trigonal() {
      return this.setting_company.enable_trigonal == '1'
    },
    canMarkAsUnread() {
      if(!this.checkGroupEmails || this.checkGroupEmails.length == 0)
        return false

      if(this.checkGroupEmails.length > 1)
        return false

      if(this.checkGroupEmails[0].username === this.userInfo.user.user_name)
        return false
      
      if(!this.checkGroupEmails.some(e => e.read))
        return false

      return true
    },
    tableBodyHeight() {
      return "height:calc(100% - 41px)";
    },
    mailFromDisplayType() {
      if(!this.$store.state.user.mailsSettings?.email_display_from_type) return ""
      return this.$store.state.user.mailsSettings?.email_display_from_type;
    },
    filterList() {
      let key = this.$route.query.search_key;
      if (key && key.indexOf("tag") >= 0) {
        this.$set(this.columnObj, "Tag", true);
      }
      const mailList = this.mailList.map(item => {
        if(!item.to && item.email_participants.to) {
          item.to = [];
          item.to = this.handleSyncList(item.email_participants.to);
        }
        if(!item.cc && item.email_participants.cc) {
          item.cc = [];
          item.cc = this.handleSyncList(item.email_participants.cc);
        }
        if(!item.bcc && item.email_participants.bcc) {
          item.bcc = [];
          item.bcc = this.handleSyncList(item.email_participants.bcc);
        }
        item.toUsers = this.getUsernames(item.to);
        item.tooltipTags = this.getTooltipTags(item.tags);
        item.mailboxes = this.getMailBoxes(item.line_ids)

        if(this.searching) {
          let keywords = this.getHighlights('subject');
          item.htmlSubject = this.buildTextWithHighlight(keywords, item.subject);

          keywords = this.getHighlights('from');
          let displayText = ""
          
          if(item.box === "SENT") displayText = item.username
          else displayText = this.mailFromDisplayType === "email" ? item.from : item.username;

          item.htmlFrom = this.buildTextWithHighlight(keywords, displayText);

          keywords = this.getHighlights('to');
          item.htmlTo = this.buildTextWithHighlight(keywords, item.toUsers.transformedName);
          item.htmlVerticalTos = [];
          Array.isArray(item.to) && item.to.forEach(t => {
            item.htmlVerticalTos.push(
              {
                username: this.buildTextWithHighlight(keywords, t.username)
              }
            )
          })

          keywords = this.getHighlights('body');
          item.htmlPreviewBody = this.buildTextWithHighlight(keywords, item.preview_body);

          item.htmlTags = [];
          keywords = this.getHighlights('tag');
          item.tags.forEach(tag => {
            item.htmlTags.push(this.buildTextWithHighlight(keywords, tag));
          })
        }
        !this.isPreloadEmailBody && delete item.body;
        return item;
      });
      return mailList;
    },
    statusFilter() {
      if (
        this.$route.fullPath == "/mails?page=1" ||
        this.$route.fullPath == "/mails"
      ) {
        return true;
      }
      if (this.$route_box() && this.$route_box().toLowerCase() == "sent") {
        return true;
      }
    },
    totalNumSep() {
      const total = this.mailList.length > this.totalNum ? this.mailList.length : this.totalNum;
      var parts = (total || "0").toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
    rowHeight() {
      let height = this.previewMailBody ? ROW_HEIGHT_WITH_PREVIEW : ROW_HEIGHT;
      (this.lockedPlane || this.previewing) && this.previewVertical && ( height += 24 )
      return height;
    },
    listSelect() {
      if(!this.selectedRow)
        return -1;
      return this.mailList.findIndex(item => item.id == this.selectedRow.id);
    },
    checkGroupEmails() {
      if(!this.checkGroup)
        return [];

      const arr = [];
      this.checkGroup.forEach(id => {
        const e = this.mailList.find(e => e.id == id);
        e && arr.push(e);
      })
      return arr;
    },
    lockedPlane() {
      return this.setting_mails.locked_reading_plane == "true";
    },
    isActivityPage() {
      return !this.$route_folder_id()
        && !this.$route_box()
        && !this.$route_archived()
        && !this.$route_status()
        && !this.$route.query.is_star
        && !this.$route.query.me
    },
    isShowAssignmentTabs() {
      return this.p_enabled_assignment && this.isActivityPage && !this.isQuickSearch
    },
    isQuickSearch() {
      return this.$route.query.search_key;
    },
    isAdvancedSearch() {
      return this.$route.query.search == 'advance';
    },
    defaultAssignmentTab() {
      return this.isAdvancedSearch ? this.assignmentTabs.ALL.name : this.assignmentTabs.OPEN.name
    },
    currentTab() {
      return Object.values(this.assignmentTabs).find(t => t.name == this.currentTabName)
    },
    pageUpDownDistance() {
      if(this.previewing && !this.previewVertical)
        return 150
      return 300
    },
    currentAssignTo() {
      let a = appConstant.assignedTo.ALL
      if(this.p_enabled_assignment && this.currentTab) {
        a = this.currentTabName === this.assignmentTabs.OPEN.name ? this.assignTabOpenMode : this.currentTab.data.assignedTo;
      }
      if (this.p_enabled_assignment && this.$route_folder_id() && this.hideAssigned) {
        a = appConstant.assignedTo.UNASSIGNED;
      }
      return a
    },
    currentBookmark() {
      const bookmark = this.$route.query.bm === '0' ? this.bookmark : this.autoBookmark;
      return bookmark;
    },
    hasFilterChange() {
      let hasChange = false
      const keys = Object.keys(this.filter)
      if(!keys || keys.length == 0) 
        return false

      keys.forEach(k => {
        if(!this.filter[k]) return
        if(typeof this.filter[k] == 'object') {
          !util.compare2Object(this.filter[k], this.defaultFilterValue[k]) && (hasChange = true)
          return
        }
        if(this.filter[k] != this.defaultFilterValue[k]) {
          hasChange = true
        }
      })
      return hasChange
    },
    isPreloadEmailBody() {
      return this.setting_mails?.preload_email_body === "true"
    }
  },
  watch: {
    "$route.params"() {
      const {detail_Id, bm} = this.$route.query

      if(this.$route.query.openMode && (this.$route.query.openMode !== localStorage.getItem(LocalStorageConstant.OPEN_MODE))) {
        localStorage.setItem(LocalStorageConstant.OPEN_MODE, this.$route.query.openMode);
        this.assignTabOpenMode = this.$route.query.openMode;
      }
      if (bm && this.currentBookmark) {
        this.reloadEmailListWithBookmark(this.currentBookmark.email_id);
        return;
      }
      if (detail_Id) return;

      // this.initFilterValues()
      
      // this.showFilter = false;
      this.checkGroup = [];
      this.isSelectAll = false;
      this.sortField = null;
      this.sortMethod = null;
      // this.dateShortcut = undefined;
      // this.dateIndicatorShown = false;
      this.handleConfigurationData()
      this.$nextTick(_ => {
        this.closeMailContextMenu();
        this.closeMailFolderPoptip();
        this.handleMailTabs();
        !this.bookmark && this.getBookmark();
        if(this.goingToBookmark && this.bookmark && this.bookmark.email_id) {
          this.reloadEmailListWithBookmark(this.bookmark.email_id);
        } else {
          this.reloadEmailList();
        }
      })
    },
    "folder.star": {
      immediate: true,
      handler(val) {
        this.$emit("starred", val)
      }
    },
    rowHeight: {
      immediate: true,
      handler(val, oldVal) {
        document.documentElement.style.setProperty('--mail-row-height', `${val}px`);
        if(oldVal && val !== oldVal)
          this.$nextTick(() => this.$refs.mailsTableScroller && this.$refs.mailsTableScroller.handleResize())
      }
    },
    isSelectAll(val) {
      this.selectAllChanged(val);
    },
    expandedPreview(status) {
      this.SET_EXPANDED_PREVIEW_MODE(status)
    },
    p_enabled_assignment: 'handleColumnSize',
    landingToLastSeenEmail: {
      immediate: true,
      handler(val) {
        if(!val) return
        setTimeout(_ => {
          this.restoreLastViewedMail()
          this.SET_LANDING_TO_LAST_SEEN_EMAIL(false)
        }, 1200)
      }
    }
  },
  components: {
    abort,
    empty,
    folderDetail,
    mailDetail,
    MailDetailPlaceholder,
    mailReport,
    tagEmails,
    "ch-status": Cstatus,
    RightMenuTable,
    ResizableTableHeader,
    avatar,
    mailItemContextmenu,
    assignment,
    mailTabs,
    RecycleScroller,
    EmailFiling,
    EmailListHorizontal,
    EmailListVertical,
    InappMailWindow,
    InappMailMinimize,
    MailDetailAttachmentPreviewModal,
    MailFilter,
    MailPoptipMenu,
    DropdownButton
  }
};
</script>

<style lang="scss" scoped>
.mail-toolbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: var(--background-color);
  font-size: 13px;
  padding: 6px 16px;

  .left {
    flex: 1;
    min-width: 0;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 8px;
  }
  .right {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
  .assignment-tabs {
    display: flex;
    align-items: center;
    margin-right: 16px;
    font-size: 13px;
    .tab {
      padding: 6px 8px;
      margin-right: 8px;
      height: 32px;
      border-radius: 4px;
      &:hover {
        cursor: pointer;
        background: var(--hover-on-background-color);
      }
      &.active {
        .ivu-select-selection {
          color: var(--text-color);
        }
      }
    }
  }
  .assignment-tab-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &+.item {
      margin-left: 16px;
    }
    .label {
      margin-left: 8px;
    }
    .checkbox-label {
      margin-left: 4px;
      font-size: 13px;
    }
  }
  .filter-button {
    display: flex;
    align-items: center;
    margin-left: 8px;
    padding: 4px 8px;
    border-radius: 4px;
    gap: 4px;
    border: none;
    background-color: var(--component-color);
    color: var(--text-color);
    font-weight: normal;
    cursor: pointer;
    margin-right: 16px;
    svg {
      fill: var(--text-color);
    }
    &.active {
      background-color: var(--primary-color);
      color: var(--neutral);
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      svg {
        fill: var(--neutral);
      }
    }
  }
  .dropdown-button {
    @extend .highlight-btn !optional;
    margin-right: 8px;
    padding: 0;
    font-weight: normal;
  }
  .devide-line {
    height: 60%;
    width: 1px;
    background-color: #808695;
    opacity: 80%;
  }
  .noSelect {
    line-height: 54px;
    margin-bottom: 0;
  }
  .abort-btn {
    width: 32px;
    height: 26px;
    line-height: 26px;
    text-align: center;
  }
  .actionable-icon-tooltip {
    .bookmark-icon-actionable {
      padding: 0 8px 0 16px;
      display: flex;
      align-items: center;
      &.disabled {
        cursor: not-allowed;
      }
    }
    .actionable-icon {
      svg {
        fill: var(--text-color);
      }
    }
  }
  .email-filing {
    position: fixed;
    z-index: 1000;
  }
  .email-filing-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
  }
  .selection-actions {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;

    a {
      display: flex;
      color: var(--text-color);

      img {
        width: 100%;
        height: auto;
      }
      svg {
        fill: var(--text-color);
      }
    }
    .iconfont-not-spam {
      font-size: 16px;
    }
    .iconfont-archived {
      font-size: 15px;
    }
  }
  .more {
    display: inline-flex !important;
    margin-left: 8px;
  }
}

.main-content-wrapper {
  display: flex;
  flex: 1 0;
  overflow: hidden;
  position: relative;
  flex-direction: column;

  .contain-wrapper {
    flex: 1;
    min-width: 400px !important;
    min-height: 30px !important;
    background-color: var(--surface-color);
    z-index: 101;
  }
}
.preview-vertical {
  flex-direction: row;
  .contain-wrapper {
    padding: 16px 0px 16px 8px;
    .table-contain {
      min-width: unset;
    }
  }
}
.half-size-list {
  flex: 1;
  min-height: 0 !important;
  min-width: 400px !important;
}
table.mails-table-header .filterHeads {
  td {
    box-sizing: border-box;
    background-color: #ead1dd;
    padding-left: 5px;
	}

  input {
    width: 100%;
    outline: none;
    border: none;
    background: transparent;
    font-size: 13px;
    text-align: left;
    color: #f00 !important;

    &::placeholder {
			font-weight: normal;
      font-style: italic;
      color: #f00 !important;
      opacity: 0.8 !important;
    }
  }
  .mailDatePicker {
    input {
      &::placeholder {
        font-weight: normal;
        font-style: italic;
        color: #f00 !important;
        opacity: 0.8 !important;
      }
    }
  }
  .table-time, .mailStatus, .table-assignment {
    padding: 0;
  }
}
.datetime-filter-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 8px 16px;
  border-bottom: 1px solid var(--lighter-border-color);
  background: var(--highlight-color);
  font-weight: bold;
  .ic-remove {
    cursor: pointer;
    i {
      font-size: 20px;
    }
    &:hover {
      color: var(--primary-color);
    }
  }
}
.mail {
  .home-page-table-mail {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 3px;
  }

  .mails-table-header {
    width: 0;
    table-layout: fixed;
    overflow-y: hidden;
    overflow-x: hidden;
    &:hover {
      .mail-filter-icon {
        display: inline;
        opacity: 0.5;
      }
    }
    th {
      padding-left: 0;
      line-height: 25px;
      &.clickable {
        cursor: pointer;
        font-weight: bold;
      }
      &.statusIcon {
        padding: 0.92857143em 0;
      }
      &.mail-icons {
        padding-left: 33px;
        width: 151px;
      }
      &.mailStatus {
        width: var(--status-td-width);
        min-width: 35px;
      }
      &.mailFiled {
        width: var(--file-td-width);
        padding: 0;
			}
      &.table-from {
        width: var(--from-td-width);
        padding-left: 5px;
      }
      &.table-to {
        width: var(--to-td-width);
        padding-left: 5px;
      }
      &.table-time {
        width: var(--time-td-width);
        .sort {
          cursor: pointer;
          padding: 4px;
        }
        .date-picker {
          position: absolute;
          right: 8px;
          cursor: pointer;
        }
      }
      &.table-subject {
        width: var(--subject-td-width);
        padding-left: 5px;
        min-width: 300px;
      }
      &.table-mailbox {
        width: var(--mailbox-td-width);
        padding-left: 5px;
      }
      &.table-assignment {
        width: var(--assignment-td-width);
        padding: 2px 0 2px 10px;
      }
      &.table-tag {
				padding: 5px;
      }
      .mail-filter-icon {
        display: none;
        position: absolute;
        margin-left: 16px;
        fill: var(--text-secondary);
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .mails-table-scroller {
    height: 100%;
    width: 100%;
    overflow-y: auto !important;
    overflow-y: overlay !important;
    overflow-x: hidden;
    padding-bottom: 25px;
    padding-right: 3px;
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 10px;
      height: 10px;
    }
  }
}

.unActive {
  color: var(--text-color-disable) !important;
  cursor: not-allowed !important;
}
.NoResultText {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}
.tags-cell {
  position: absolute;
  top: 10px;
  right: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .iconfont-filter {
    font-size: 15px;
    cursor: pointer;
    vertical-align: middle;
  }

  .clearFilter {
    cursor: pointer;
    color: var(--primary-color);
    text-decoration: underline;
  }
}
.table-tag {
  width: var(--tag-td-width);
}

.iconfont-tag {
  font-size: 15px;
  padding-left: 5px;
}

.confirm-delete-header {
  display: flex;
  align-items: center;
  line-height: 20px;
  margin-right: 20px;
  b {
    display: block;
  }
}
.mail-poptip__folder-menu {
  max-height: 400px;
  overflow: auto;
  & > li > span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
</style>

<style lang="scss">
.pre-wrap {
  white-space: pre-wrap;
}
.email-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  .container {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
  }
  .home-page-table-mail {
    overflow-y: hidden !important;
    overflow-x: hidden;

    .mail-unstar-icon {
      color: var(--border-color);
    }
  }

  .mails-table {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    table-layout: fixed;
    overflow-y: hidden;
    overflow-x: hidden;
    & .row-highlight {
      box-shadow: inset 1px 0 0 #dadce0, inset 1px 0 0 #dadce0, 0 6px 8px 0 rgba(60, 64, 67, 0.3), 0 0px 3px   rgba(60, 64, 67, 0.15);
    }
  }

  .mails-table-scroller {
    .vue-recycle-scroller__item-view {
      display: inline-table;
      table-layout: fixed;
      will-change: auto !important;
    }
    .vue-recycle-scroller__item-wrapper {
      overflow-x: auto;
    }
  }

  .no-search-result {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .table-loading {
    @extend .no-search-result;
    font-size: 16px;
  }

  .prev-loader {
    margin: 4px auto;
  }

  .exceed-limit {
    font-style: italic;
    color: var(--label-color);
    text-align: center;
    position: relative;
    margin-bottom: -20px;
    margin-top: 10px;
  }

  .filterHeads {
    .statusSelect {
      .ivu-select-selection {
        border: none;
        box-shadow: none;
        background: none;
      }
    }
  }
  .hasRead {
    .badge {
      &.small {
        .ivu-badge-count {
          color: #8ca0b3;
          background: #d8e2e7;
        }
      }
    }
  }
  .mail-toolbar {
    .select-no-border {
      .ivu-select-selected-value, .ivu-select-item {
        font-size: 13px !important;
      }
      &.selected-list__btn {
        width: auto;
        &.active {
          padding: 4px 0 4px 12px;
          border-radius: 4px;
          gap: 4px;
          border: none;
          background-color: #0097fb;
          box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
            0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.12);
          
          .ivu-select-selection {
            color: var(--neutral);
          }
          .ivu-select-arrow {
            color: var(--neutral);
          }
        }
      }
    }
  }
}
.expandedPreview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
</style>

<style lang="scss">
.assignment-tabs {
  .tab {
    &.active {
      .ivu-select-selection {
        color: var(--text-color);
        font-weight: bold;
      }
    }
  }

  .tab.active .ivu-select-selection .ivu-select-arrow {
    color: var(--text-color);
    font-weight: bold;
  }
}
</style>
