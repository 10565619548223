<template>
  <div class="header-layout">
    <div class="header_wrapper" v-show="!(previewExpanded || isOpeningInappWindow) || !isMailRoute">
      <span class="header-title"><slot name="title"></slot></span>
      <div class="header_right">
        <div v-if="showSearchBox" class="search_wrapper">
          <historyInput
                        :category="'mail_search'"
                        :height="211"
                        :itemHeight="45"
                        :class="{'mail':search_type.value==='mails'}"
                        v-if="search_type.value==='mails'"
                        @searchByKey="searchByKey"
                        :placeholder="'Search'"
                        ref="mail_search">
          </historyInput>
          <!-------------------ship search------------------>
          <dynamicInput ref="shipSearch"
                        :part="'ship'"
                        :placeholder="'IMO/Name/Call Sign'"
                        v-if="search_type.value==='shipping'"></dynamicInput>
          <!-------------------contact search------------------>
          <dynamicInput ref="contactSearch"
                        :part="'contact'"
                        v-if="search_type.value==='Contacts'"></dynamicInput>
          <!-------------------Advance search------------------>
          <div class="advanceIcon" v-show="search_type.value==='mails'||search_type.value==='shipping'"
              @click="openAdvance">
            <Tooltip placement="bottom" content="Advanced Search">
              <i class="iconfont-plus"></i>
            </Tooltip>
          </div>
        </div>
        <Tooltip
          ref="newVersion"
          class="ch-topfunc-item new-version"
          content="A new version of MarineTraffic Inbox is available. Click to refresh."
          placement="bottom"
        >
          <Button type="error" @click="updateServiceWorker">Update</Button>
        </Tooltip>
        <!-------------------ship  position------------------>
        <Tooltip
          v-if="setting_company.shipping_module_turn_off=='0' && (activeShip || $route.name==='shipping')"
          content="Quick create ship position"
          class="ch-topfunc-item"
        >
          <span @click="updateShipPosition({id:null})">
            <i class="iconfont-ship-add"></i>
          </span>
        </Tooltip>
      </div>
      <!-- --------------------Advanced search-------------------------- -->
      <mailAdvanced ref="mailAdvanced" @submitSearch="submitSearch"></mailAdvanced>
      <shipAdvanced ref="shipAdvanced"></shipAdvanced>
      <!-- --------------------update ship position------------------------- -->
      <updatePosition ref="updatePosition"></updatePosition>
    </div>
    <div class="main-content">
      <slot></slot>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
  import popover from '../components/Popover'
  import historyInput from './search/historyInput.vue'
  import dynamicInput from './search/dynamicInput.vue'
  import mailAdvanced from './advance/mail/advanceSearch.vue'
  import shipAdvanced from './advance/ship/advanceSearch.vue'
  import updatePosition from './updatePosition/updatePosition.vue'
  import avatar from '@/pages/components/avatar.vue'
  import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
  import api from 'api'
  import util from 'util'
  import storageConstant from '@/common/constants/local-storage.constant';
  import { hotkeyHandler, zohoRegister } from '@/mixins';

  export default {
    name: 'CHheader',

    data() {
      return {
        searchHistory: [],
        searchShip: '',
        searchType: [
          {name: 'Mails', value: 'mails'},
          {name: 'Ships', value: 'shipping'},
          {name: 'Contacts', value: 'Contacts'},
        ],
        Socket: {},
      }
    },
    props: {
      fromMailPage: {
        type: Boolean,
        default: false
      },
      showSearchBox: {
        type: Boolean,
        default: true
      }
    },
    mounted () {
      this.handleSocket();
      this.handleNewVersion()
    },
    mixins: [hotkeyHandler, zohoRegister],
    methods: {
      ...mapActions({
        readEmail: 'readEmail',
        composeMail: 'composeMail',
        incrementUnreadCount: 'incrementUnreadCount',
      }),
      ...mapMutations([
        'SET_USER',
        'SET_PERMISSIONS_SETTINGS',
        'TOGGLE_SIDE_BAR',
      ]),
      handleSocket(){
        let socket = this.$ActionCable.subscriptions.subscriptions[0];
        let self = this;
        socket.received.header = function (data) {
          if (data.type === "Notification") {
            self.notifications = data.message.concat(self.notifications)
            self.incrementUnreadCount();
            data.message.forEach(message => {
              const {commentable_id, commentable_type, content, assignable_type} = message.noticeable;
              if (commentable_type === "Email") {
                util.resetCachedEmails("me=");

                const shortContent = message.content.split(content)[0];
                self.$Toastr.dispatch('show', {message});
                util.sendMessageToSw(
                  {
                    message: "showNotification",
                    params: {
                      type: "mentioned",
                      data: {
                        username: message.from.user_name,
                        content: shortContent,
                        id: commentable_id
                      }
                    }
                  }
                );
              }
              const { noticeable_type } = message;
              if (noticeable_type === "Assignment" || noticeable_type === "Unassignment") {
                self.$Toastr.dispatch('show', {message});
              }
            })
          }
          if (data.type === 'Permission') {
            self.SET_USER(data.message.user);
            self.SET_PERMISSIONS_SETTINGS(data.message.company_settings);
            const storeUser = {
              auth_token: self.userInfo.auth_token,
              company: self.userInfo.company,
              user: data.message.user
            };
            util.storeWithExpiration.set(storageConstant.USER, storeUser, 0);
            util.storeWithExpiration.set(storageConstant.SETTING_PERMISSIONS, data.message.company_settings, 0);
          }
        };
        socket.received.forceLogout = function(data) {
          if(data && data.user_id === self.userInfo.user.id) {
            self.$store.dispatch('Logout')
          }
        }
      },
//---------------------------------------advance search
      openAdvance(){
        if (this.search_type.value == 'mails') {
          this.$refs.mailAdvanced.show();
        }
        if (this.search_type.value == 'shipping') {
          this.$refs.shipAdvanced.show();
        }
      },
      submitSearch(){
        this.$emit('searchMail')
      },
      editShipSearch(val){
        this.$refs.shipAdvanced.show(val);
      },
//---------------------------------------search key(mail)
      searchByKey(searchKey) {
        if(!searchKey)
          return;
        let query = Object.assign({}, this.$route.query);
        if (this.$route_box() || this.$route_status() || this.$route_archived() || this.$route_line_id() ||  this.$route_folder_id()) {
          query.search_key = searchKey;
          this.$router.push({path: '/mails', query: query});
          return;
        }
        this.$router.push('/mails?search_key=' + encodeURIComponent(searchKey));
      },
      updateShipPosition (params) {
        this.$refs.updatePosition.show(params)
      },
      handleHotkey(e) {
        if((e.altKey && e.key == 'œ') || (e.altKey && e.key == 'q' || (e.altKey && e.keyCode == 81))) { // Alt Q
          e.preventDefault();
          this.focusOnSearch();
        }
      },
      focusOnSearch() {
        this.$refs.mail_search && this.$refs.mail_search.$refs.search_input.focus();
        this.$refs.shipSearch && this.$refs.shipSearch.$refs.search_input.focus();
        this.$refs.contactSearch && this.$refs.contactSearch.$refs.search_input.focus();
      },
      async updateServiceWorker() {
        window.location.reload(true);
      },
      handleNewVersion() {
        window._newVersionClass
          && this.$refs.newVersion
          && this.$refs.newVersion.$el
          && this.$refs.newVersion.$el.classList.add(window._newVersionClass)
      }
    },
    computed: {
      ...mapState({
        user: state => state.user
      }),
      ...mapGetters([
        'userInfo',
        'setting_company',
        'previewExpanded',
        'isOpeningInappWindow'
      ]),
      isMailRoute() {
        return this.$route.path === "/mails";
      },
      activeShip(){
        if (this.$route.name === "addShip" || this.$route.name === "editShip" || this.$route.name === "positionView") {
          return 'color: #184d77;font-weight: bold;'
        }
      },
      activeContact(){
        if (this.$route.name === "Addcontact" || this.$route.name === "editContact" || this.$route.name === "addCompany") {
          return 'color: #184d77;font-weight: bold;'
        }
      },
      search_type() {
        let name = this.$route.name;
        if (name === 'mails')
          return {name: 'Mail', value: 'mails'};

        if (name === 'shipping' || name === 'addShip' || name === 'editShip' || name === 'positionView')
          return {name: 'Ships', value: 'shipping'};

        const contacts = ['addContact', 'addCompany', 'addDepartment', 'editCompany', 'editDepartment', 'editContact','Contacts']
        if(contacts.findIndex(n => name == n) > -1)
          return {name: 'Contacts', value: 'Contacts'};

        return {name: 'Mail', value: 'mails'};
      }
    },
    components: {
      popover,
      mailAdvanced,
      shipAdvanced,
      dynamicInput,
      historyInput,
      updatePosition,
      avatar,
    },
  }
</script>

<style lang="scss" scoped>
  .header-layout {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .header_wrapper {
    min-height: 40px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px 16px;
    z-index: 1000;
    background-color: var(--menu-color);
    color: var(--text-color);
    border-top: 1px solid var(--border-color);

    .menu {
      margin-left: 16px;
      font-size: 28px;
      cursor:pointer;
    }
    &>span {
      flex: 1;
      min-width: 0;
    }
    .header-title {
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 0.7;
      margin-left: 16px;
      max-width: calc(50% - 230px);
      i {
        cursor: pointer;
        &:hover {
          font-weight: bold;
        }
      }
    }
    h2 {
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      text-align: left;
      color: var(--text-color);
      font-weight: bold;
    }
    .header_right {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 16px;

        div + div {
          margin-left: 32px;
        }

        .search_wrapper {
          width: 430px;
          min-width: 200px;
          position: relative;
          display: flex;
          align-items: center;
          .advanceIcon {
            position: absolute;
            right: 16px;
            top: 0;
            padding-top: 1px;
            line-height: 38px;
            z-index: 4;
            cursor: pointer;

            .iconfont-plus {
              cursor: pointer;
              font-size: 16px;
              opacity: 0.6;
              color: #828d9d;
              transition: all 0.2s;
              &:hover {
                opacity: 1;
              }
            }
          }
        }
        .header-pop {
          flex-shrink: 0;
        }
        .ch-topfunc-item {
          display: flex;
          position: relative;
          justify-content: space-between;
          align-items: center;
          height: 100%;
          line-height: 24px;

          .iconfont, i {
            cursor: pointer;
            background: none;
            font-size: 18px;
            vertical-align: bottom;
          }
          .iconfont-zoho {
            font-size: 22px;
          }
          .iconfont-ship-add {
            font-size: 22px;
          }
        }
        .new-version {
          cursor: pointer;
          display: none !important;
          button {
            padding: 6px 16px;
            font-size: 13px;
          }
          &.green {
            display: flex !important;
            button {
              border: 1px solid #34a853;
              color: #34a853;
              background: #e4f1ea;
            }
          }
          &.orange {
            display: flex !important;
            button {
              border: 1px solid #fbbc04;
              color: #fbbc04;
              background: #f8f3e5;
            }
          }
          &.red {
            display: flex !important;
            button {
              border: 1px solid #ea4335;
              color: #ea4335;
              background: #f7e7e7;
            }
          }
        }
    }
  }
  .main-content {
    flex: 1;
    min-height: 0;
  }


  .ch-menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    line-height: 16px;
    font-size: 16px;
    font-weight: bold;
  }

  .ch-disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
</style>

<style lang="scss">
.header_wrapper {
  .ivu-poptip-arrow {
    right: 2px !important;
  }
  .ivu-poptip-body {
    padding: 0;
  }
  .iconfont-bell {
    font-size: 18px;
  }

  .header-title {
    i, svg {
      margin-right: 8px;
    }

    svg {
      position: relative;
      top: -2px;
    }
  }
}
</style>
